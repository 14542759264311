export const COURSE_INFO_REQ ='COURSE_INFO_REQ'
export const COURSE_INFO_RES ='COURSE_INFO_RES'

export const CRS_PROGRESS_REQ ='GET_PROGRESS_REQ'
export const CRS_PROGRESS_RES ='CRS_PROGRESS_RES'

export const SELECTED_SECTION ='SELECTED_SECTION'
export const SELECTED_TOPIC ='SELECTED_TOPIC'

export const SET_NAV_ITEMS ='SET_NAV_ITEMS'
export const SET_SIDEBAR = 'SET_SIDEBAR'
export const SET_DRAWER = 'SET_DRAWER'

export const LRS_INITIALISE = 'LRS_INITIALISE'
export const SCORM_INITIALISE = 'SCORM_INITIALISE'
export const COURSE_TRACKING_INIT = 'COURSE_TRACKING_INIT'
export const COURSE_TRACKING_SAVED = 'COURSE_TRACKING_SAVED'
export const COURSE_TRACKING_SAVE = 'COURSE_TRACKING_SAVE'
export const SET_TOPIC_PRORESS ='SET_TOPIC_PRORESS'
export const UPDATE_QUIZ_SCORE = 'UPDATE_QUIZ_SCORE'
export const RESUME_QUIZ = 'RESUME_QUIZ'
export const SHORTCUT_COMPLETION = "SHORTCUT_COMPLETION"
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER'

export const STORE_USER_INFO = 'STORE_USER_INFO'
export const USER_ACL_REQ= 'USER_ACL_REQ'
export const USER_ACL_RES= 'USER_ACL_RES'

export const EDIT_STATE = 'EDIT_STATE'
export const EDIT_MENU_DROPDOWN = 'EDIT_MENU_DROPDOWN'

export const SAVE_COMMENT_REQ = 'SAVE_COMMENT_REQ'
export const SAVE_COMMENT_RES = 'SAVE_COMMENT_RES'
export const DELETE_COMMENT_REQ = 'DELETE_COMMENT_REQ'
export const UPDATE_COMMENT_REQ = 'UPDATE_COMMENT_REQ'

export const SAVE_LEARNING_AID = 'SAVE_LEARNING_AID'
export const WYSIWYG_LEARNING_AID = 'WYSIWYG_LEARNING_AID'

export const TEMPLATE_LIST ='TEMPLATE_LIST'
export const ASSIGN_TEMPLATE ='ASSIGN_TEMPLATE'
export const SET_ASSIGNED_TEMPLATE ='SET_ASSIGNED_TEMPLATE'

export const HIGHLIGHT_LEARNING_AID = 'HIGHLIGHT_LEARNING_AID'
export const UPDATE_STATUS_LEARNING_AID = 'UPDATE_LEARNING_AID'

export const VIDEO_CURRENT_TIME = 'VIDEO_CURRENT_TIME'

export const ADD_NEW_LEARNING_AID_REQ = 'ADD_NEW_LEARNING_AID_REQ'
export const LEARNING_AID_ADDED_RES = 'LEARNING_AID_ADDED_RES'

export const DELETE_LEARNING_AID_REQ = 'DELETE_LEARNING_AID_REQ'
export const LEARNING_AID_REMOVED_RES = 'LEARNING_AID_REMOVED_RES'

export const SUPPLIMENTARIES_REQ = 'SUPPLIMENTARIES_REQ'
export const SUPPLIMENTARIES_RES = 'SUPPLIMENTARIES_RES'

/** Notifications */
export const APPLY_TEMPLATE_TOAST ='APPLY_TEMPLATE_TOAST'
export const ADD_LEARNINGAID_TOAST ='ADD_LEARNINGAID_TOAST'
export const REMOVE_LEARNINGAID_TOAST ='REMOVE_LEARNINGAID_TOAST'
export const SAVED_AS_TEMPLATE_TOAST ='SAVED_AS_TEMPLATE_TOAST'
export const LEARNING_AID_CLONED_TOAST = 'LEARNING_AID_CLONED_TOAST'

export const SAVE_AS_TEMPLATE_REQ = 'SAVE_AS_TEMPLATE_REQ'
export const TEMPLATE_ADDED_RES  = 'TEMPLATE_ADDED_RES'

// clone topic
export const CLONE_LEARNING_AID_REQ = 'CLONE_LEARNING_AID_REQ'
export const LEARNING_AID_CLONED_RES = 'LEARNING_AID_CLONED_RES'

//cuepoints ,quiz ,content text so named as addon
export const GET_CESTANDARD_JSON = 'GET_CESTANDARD_JSON'
export const GET_VIDEO_ADDON = 'GET_VIDEO_ADDON'
export const SET_VIDEO_ADDON = 'SET_VIDEO_ADDON'

/** updating the topic information */
export const EDIT_TOPIC= 'EDIT_TOPIC'
export const UPDATE_TOPIC= 'UPDATE_TOPIC'

export const TOPIC_UPDATED_TOAST = 'TOPIC_UPDATED_TOAST'

/** Learning aid search */
export const ACTIVE_COURSES_LIST ='ACTIVE_COURSES_LIST'
export const SEARCH_LRN_AIDS ='SEARCH_LRN_AIDS'
export const SEARCH_RESULT_LRN_AIDS ='SEARCH_RESULT_LRN_AIDS'

export const EXISTING_LRN_AID_ADD_REQ = 'EXISTING_LRN_AID_ADD_REQ'

/** CE Asset Library  */
export const CE_ASSETS_LIBARARY_REQ = 'CE_ASSETS_LIBARARY_REQ'
export const CE_ASSETS_LIBARARY_RES = 'CE_ASSETS_LIBARARY_RES'
export const CE_CLIENT_ASSETS = 'CE_CLIENT_ASSETS'

export const CE_ASSETS_WISHLISTS_REQ = 'CE_ASSETS_WISHLISTS_REQ'
export const CE_ASSETS_WISHLISTS_RES = 'CE_ASSETS_WISHLISTS_RES'



export const CE_ASSETS_LINKED_REQ = 'CE_ASSETS_LINKED_REQ'
export const CE_ASSETS_LINKED_RES = 'CE_ASSETS_LINKED_RES'

/** CE Global Style  */
export const GLOBAL_COMSPACE_STYLE = 'GLOBAL_COMSPACE_STYLE'
export const GLOBAL_FONT = 'GLOBAL_FONT'
export const GLOBAL_TOPIC_STYLE = 'GLOBAL_TOPIC_STYLE'

export const IS_LINEAR = 'IS_LINEAR'
export const  IS_HIDE_INSTRUCTION= 'IS_HIDE_INSTRUCTION'
export const  HOME_PAGE_IMAGE= 'HOME_PAGE_IMAGE'
export const GLOBAL_STYLE_REQ = 'GLOBAL_STYLE_REQ'
export const GLOBAL_STYLE_RES = 'GLOBAL_STYLE_RES'
export const UPDATE_GLOBAL_STYLE ='UPDATE_GLOBAL_STYLE'
export const GLOBAL_LRN_SETTINGS = 'GLOBAL_LRN_SETTINGS'

export const GLOBAL_STYLE_UPDATED_TOAST = 'GLOBAL_STYLE_UPDATED_TOAST'

export const GET_ARCHIVE_COURSES_REQ  = 'GET_ARCHIVE_COURSES_REQ'
export const GET_ARCHIVE_COURSES_RES   = 'GET_ARCHIVE_COURSES_RES'

export const GET_ARCHIVE_SEARCH_REQ  = 'GET_ARCHIVE_SEARCH_REQ'
export const GET_ARCHIVE_SEARCH_RES   = 'GET_ARCHIVE_SEARCH_RES'

export const GET_SEARCH_BY_CID_REQ  =  'GET_SEARCH_BY_CID_REQ'
export const SET_SEARCH_BY_CID_RES  =  'SET_SEARCH_BY_CID_RES'

export const ADD_VIDEO_SUPPLIMENTARIES  = 'ADD_VIDEO_SUPPLIMENTARIES'

export const GET_QUIZ_QUESTIONS_REQUEST  =  'GET_QUIZ_QUESTIONS_REQUEST'
export const GET_QUIZ_QUESTIONS_RESPONSE  =  'GET_QUIZ_QUESTIONS_RESPONSE'

export const GET_QUIZ_ADD_QUESTIONS_REQUEST  =  'GET_QUIZ_ADD_QUESTIONS_REQUEST'
export const GET_QUIZ_ADD_QUESTIONS_RESPONSE  =  'GET_QUIZ_ADD_QUESTIONS_RESPONSE'

export const GET_QUIZ_UPDATE_QUESTIONS_REQUEST  =  'GET_QUIZ_UPDATE_QUESTIONS_REQUEST'
export const GET_QUIZ_UPDATE_QUESTIONS_RESPONSE  =  'GET_QUIZ_UPDATE_QUESTIONS_RESPONSE'
// Quiz bank 
export const ADD_QUIZ_QUESTION_REQ  =  'ADD_QUIZ_QUESTION_REQ'
export const QUIZ_QUESTIONS_RES  =  'QUIZ_QUESTIONS_RES'

export const SAVE_VIDEO_CUEPOINTS = 'SAVE_VIDEO_CUEPOINTS'

export const GET_CRS_STRUCTURE ='GET_CRS_STRUCTURE'
export const SET_CRS_STRUCTURE ='SET_CRS_STRUCTURE'
export const UPDATE_MARKUP ='UPDATE_MARKUP'

export const AUTH_GOES_WRONG='AUTH_GOES_WRONG'

export const COURSE_COMPLETION_NOTIFIED='COURSE_COMPLETION_NOTIFIED'

export const QUIZ_TRACKING_EMAIL_REQUEST='QUIZ_TRACKING_EMAIL_REQUEST'

/* publish action type's */
export const PUBLISH_CRS_INFO='PUBLISH_CRS_INFO'
export const PUBLISH_CRS_GET_LRN_ID='PUBLISH_CRS_GET_LRN_ID'
export const ADD_NEW_LRN_IN_PUBLISH='ADD_NEW_LRN_IN_PUBLISH'
export const DELETE_LRN_IN_PUBLISH='DELETE_LRN_IN_PUBLISH'
export const PUBLISH_CHANGES_TO_CRS='PUBLISH_CHANGES_TO_CRS'
export const PUBLISH_VERSION='PUBLISH_VERSION'
export const PUBLISH_RESPONSE='PUBLISH_RESPONSE'

//auth active check
export const SESSION_AUTH='SESSION_AUTH'
export const SOCKET_WARNING='SOCKET_WARNING'

export const EXIT_PRE_TEST='EXIT_PRE_TEST'
export const TRANSLATION_JSON='TRANSLATION_JSON'

export const GET_PERSONALIZE_HISTORY = 'GET_PERSONALIZE_HISTORY'
export const SET_PERSONALIZE_HISTORY = 'SET_PERSONALIZE_HISTORY'
export const UPDATE_PERSONALIZE_HISTORY = 'UPDATE_PERSONALIZE_HISTORY'
export const UPDATE_LRN_STATUS_IN_BULK = 'UPDATE_LRN_STATUS_IN_BULK'
export const RESPONSE_LRN_STATUS_IN_BULK = 'RESPONSE_LRN_STATUS_IN_BULK'

export const HAS_LANGUAGE_VIEW='HAS_LANGUAGE_VIEW'

export const CRS_DURATION='CRS_DURATION'
export const UPDATE_CRS_DURATION='UPDATE_CRS_DURATION'

export const UPDATE_CRS_STRUCTURE='UPDATE_CRS_STRUCTURE';
export const CRS_STRUCTURE_REQUEST='CRS_STRUCTURE_REQUEST';
export const CRS_STRUCTURE_RES='CRS_STRUCTURE_RES';
export const CRS_STRUCTURE_TOST='CRS_STRUCTURE_TOST';