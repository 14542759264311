import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import './assetUploader.scss';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Button from "@material-ui/core/Button";
import AssetsPlayer from './AssetsPlayer';
import { useDispatch, useSelector } from 'react-redux';
import * as ceAssetsInfoAction from '../../../../redux/actions/ceAssetsInfoAction'
import AssetsCard from './AssetsCard';
import CloseIcon from '@material-ui/icons/Close';
import Wishlist from './Wishlist';
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Divider from '@mui/material/Divider';
import TopicVideoList from './TopicVideoList';
import useDebounce from '../../../../utils/useDebounce';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Typeahead } from 'react-bootstrap-typeahead';
import clcid_Mapping from '../../../../clcid_Mapping.json';
import PlayerConstants from '../../../../utils/PlayerConstants';
import BuildHelper from '../../../../utils/BuildHelper';




function TabPanel({ children, value, index, ...other }) {

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}{value}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) { return { id: `scrollable-auto-tab-${index}`, "aria-controls": `scrollable-auto-tabpanel-${index}` }; }

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
  height: '80vh',
  bgcolor: 'background.paper',
  borderRadius: '3px',
  p: 4,
  boxSizing: ' contentBox',
  padding: '0',
  overflow: 'hidden'
};

const AssetUploader = ({ model, data, asset, type }) => {
  const [open, setOpen] = useState(true);
  const [selectedContent, setSelectedContent] = useState(null);
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState(0);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [assetData, setAssetData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [isGif, setIsGif] = useState(false);
  const { ceAssetsInfo,
    courseEdit: { target },
    courseInfo,
    userInfo,
    ceAssetsInfo: { archiveCourses, archiveSearchCrs,clientAssets, selectedArchiveCrs, gif, images} } = useSelector(state => state);
  const [searchCrs, setSearchCrs] = useState([]);
  const [isDrawer, setIsDrawer] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const search = useDebounce(searchInputValue, 1500);
  const isTopicVideo = (target?.cmp?.name === "ArchiveVideo" && type === "video");
  const isQuestionAsset = type === "question";
  const playerType = isTopicVideo ? "lgVideo" : type ;
  const [searchTopic, setSearchTopic] = useState('Content Text');
  /*  Using this state in video search */
  const [videoSearchList, setVideoSearchList] = useState(false);
  const [visibleItems, setVisibleItems] = useState(16);
  const isVideo=type === "video";
  // showing load more button 
  const isShowMore= isVideo&& (videoSearchList?.length > 0 || !videoSearchList) && visibleItems < (videoSearchList?.length || data?.length)

 /* this one is  only used for the video type */
  const loadMoreItems = (increase) => {
    let visible=visibleItems+increase;
    setVisibleItems(visible);
    let videoAsset= videoSearchList || data || [];
    videoAsset= videoAsset?.slice(0, visible);
    setAssetData([...videoAsset] || []);
  };

  const getClientList=()=>{
    if(isGif)return clientAssets[gif];
    else return clientAssets[type];
  }

  useEffect(()=>{
  if(isVideo) loadMoreItems(0);
  else if(isGif){
    setAssetData(gif || []);
    setClientData(clientAssets[gif]||[])
  }
  else{
    setAssetData(data || []);
    setClientData(clientAssets[type]||[])
  } 
  },[data, videoSearchList, gif,clientAssets])

  const TopicVideoSearchList = ['Content Text', 'File Name', 'Topic Name', 'Course Name']

  const handlewishList=()=>{
    if (ceAssetsInfo?.wishlist?.length === 0) {
      let payload={ id: '634e9b7ff1f3c81992aa193b' };
      if(BuildHelper.isConfig())payload.id=userInfo?.user?.uid
      dispatch(ceAssetsInfoAction.ceAssetsWishlistRequest(payload))
    }
  }

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setIsGif(false);
    setSearchInputValue('');
    setClientData(clientAssets[type]);
    setAssetData(data || []);
  }

  const contentSelection = (item) => {
    let selectedAssets = item;
    if (isTopicVideo) {
      let lgPath='';
      let isMigrated=false;
      const flattenClcid = archiveCourses[item.cid]?.info?.config?.clcid;
      if (flattenClcid) {
        // setIsStreaming(false);
        lgPath = `https://d14rwvged4djyq.cloudfront.net/type3/${flattenClcid}/${item?.filepath}.mp4`;
      } else {
        // eslint-disable-next-line camelcase
        const mappedData = clcid_Mapping[item?.cid];
        // setIsStreaming(mappedData);
        lgPath = `https://d14rwvged4djyq.cloudfront.net/type3/${mappedData?.clcid}/${item?.filepath}.mp4`;
        isMigrated=mappedData;
      }

      selectedAssets = {
        ...selectedAssets,
        path:lgPath,
        isMigrated:isMigrated
      }
    }
    setSelectedContent(selectedAssets);
    setIsDrawer(!isDrawer)
  }
  const handleSubmit = (e) => {
    asset(e);
    modalClose();
  }


  useEffect(()=>{
    if(searchInputValue.length > 1 && isTopicVideo){
      let obj={};
      if(isTopicVideo && searchInputValue){
        obj={
          data:searchInputValue,
          type:searchTopic
        }
      }
     dispatch(ceAssetsInfoAction.getArchiveSearchRequest(obj));
     setIsloading(true);
    }
  }, [search])

  useEffect(()=>{
   const activeCrs= archiveSearchCrs?.filter(
      (item) => item?.filepath !== '0' && item.cid !== 0
    );
    setSearchCrs(activeCrs);
    setIsloading(false);
  },[archiveSearchCrs])



  const handleInputChange = async (e,list,result) => {
    try {
      await setSearchInputValue(e.target.value);
      if(isQuestionAsset){
        if(e.target?.value?.length>3){
          const filterData = list?.filter(item=>item.question.toLowerCase().includes(e.target.value.toLowerCase()))
          result(filterData)
        }else {
          result(list)
        }
      }else{
        if (!isTopicVideo) {
          let converted = searchInputValue.toLowerCase();
          const matchedAssets = list.filter((item) => {
            return item.name?.toLowerCase().includes(converted) || item?.id?.toLowerCase().includes(converted) || item?._id?.toLowerCase().includes(converted)
          });
          if (searchInputValue?.length <= 1) {
            if(isVideo)setVideoSearchList(false);
            else  result(list);
          } else {

            if(isVideo)setVideoSearchList([...matchedAssets] || false);
            else result(matchedAssets);
          }
        } else { setSearchCrs([]); }
      }
     
    } catch (e) {
      console.debug(e);
    }
  }

  const handleSearch = async (result,list) => {
    try {
      if (searchInputValue.length >= 1) {
        if (!isTopicVideo) {
          let matchedAssets = list;
          const converted = searchInputValue.toLowerCase();
          matchedAssets = list.filter((item) => {
            return item.name?.toLowerCase().includes(converted) || item?.id?.toLowerCase().includes(converted) || item?._id?.toLowerCase().includes(converted);
          });
          if(isVideo)setVideoSearchList([...matchedAssets] || false);
          else result(matchedAssets);
        }else{
          dispatch(ceAssetsInfoAction.getArchiveSearchRequest(searchInputValue));
          setIsloading(true);
        }
      } else {
        if(isVideo)setVideoSearchList(false);
        else result(list);
      }
    } catch (e) { console.log(e); }
  }


const modalClose =()=>{
  dispatch(ceAssetsInfoAction.setArchiveSearchResponse([]));
  model();
}

  const handleSearchType = (e) =>{
    setSearchTopic(e);
    setSearchInputValue('');
    setSearchCrs([]);
  }

  useEffect(()=>{
    if(searchTopic === 'Course Name'){
      setIsloading(false); 
      setSearchCrs(selectedArchiveCrs || []);
    }
  },[selectedArchiveCrs])

  const handleArCrsInputChange = (q) => {
    try{
      let payload = q[0]?.id || q[0]?._id || -1;
      setIsloading(true);
      setSearchInputValue('');
      setSearchCrs([]);
      if( q[0]?.selectedList){ setSearchCrs(q[0]?.selectedList || []); setIsloading(false);}
      else if(payload !==-1) dispatch(ceAssetsInfoAction.getArchiveSearchCrcIdRequest(payload));
    }catch{}
  }

  const TypeaheadBox = () => {
    try {
      return (<Typeahead
        id="search"
        // maxResults={false}
        minLength={2}
        options={archiveCourses}
        className="assets-search-box optional-search-crs-name"
        placeholder="Search By Course Name"
        aria-label="search"
        aria-describedby="search"
        onChange={handleArCrsInputChange}
        renderMenuItemChildren={(option) => (
          <div key={option?._id}>
            <span>{option?.label}</span>
          </div>
        )}
      />)
    } catch (e) { }
  }

  const handleGif=()=>{
    if (gif===null) {
      let type= Object.values(PlayerConstants.CE_ASSETS).find(e=>e?.type==='gif');
      /**
       * During configuration, we send the crsids data.
       * During the course time, the payload crsids is empty.
       * //client id changed 12/12/24
       */
      const payload={
         type:type?.id,
         clientid:userInfo?.user?.pvtid /// client id
      }
       dispatch(ceAssetsInfoAction?.ceAssetsRequest(payload));
    }
    setAssetData(gif);
    setClientData(clientAssets?.gif||[]);
    setIsGif(true);
  }
  const handleImage=()=>{
    setAssetData(images);
    setIsGif(false);
    setClientData(clientAssets?.image||[]);
  }
 let client_assets={
  'image':clientAssets?.image?.length > 0 || clientAssets?.gif?.length >0,
  'others': clientData?.length > 0
 }
 const hasAssets=client_assets[type] || client_assets['others'];

  let hasClientAssets=(BuildHelper.isConfig() && hasAssets);
  const renderTabs = () => {
    const minWidth=hasClientAssets? '33' : '50' ;
    const tabs = [
      {
        label: isQuestionAsset ? 'Questions' : 'Assets List',
        isVisible: true,
      },
      {
        label: 'Client Assets',
        isVisible: hasClientAssets,
      },
      {
        label: 'Wishlist',
        isVisible: !isQuestionAsset,
        onChange:handlewishList
      },
    ];
  
    return tabs
      .filter((tab) => tab.isVisible) // Only include visible tabs
      .map((tab, index) => (
        <Tab
          key={index}
          label={tab.label}
          className={`${activeTab===index  ? 'active-tab' : ''} tab-name`}
          {...a11yProps(index)}
          style={{ minWidth: `${minWidth}%` }}
          onClick={tab?.onChange || undefined}
        />
      ));
  };

  const assetsSearch=(list,result)=>{
    return <>
         <div className='col-lg-24 assets-search'>
                <div className='row'>
                  <div className={isTopicVideo ? 'col-lg-7' : 'col-lg-6'}>
                    <div className="toolbar-searchbar">
                      {isTopicVideo && <FormControl size='medium' className='assets-ar-video' >
                        <InputLabel id="demo-select-small" className='search-asset'>Search By</InputLabel>
                        <Select
                          labelId="demo-select-small"
                          id="demo-select-small"
                          value={searchTopic}
                          label="Search By"
                          onChange={(e) => handleSearchType(e.target.value)}
                          className="selected-search"
                          name='fs'
                        >
                          {TopicVideoSearchList.map((e, index) => <MenuItem key={index} className='select-option-list' value={e}>{e}</MenuItem>)}
                        </Select>
                      </FormControl>}
                      {(isTopicVideo && searchTopic === 'Course Name') ?
                        TypeaheadBox() : <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          placeholder={isQuestionAsset ? "Search by Question" : !isTopicVideo ? "Search by Name, ID" : `Search by ${searchTopic}`}
                          inputProps={{ 'aria-label': 'search google maps' }}
                          onChange={(e)=>handleInputChange(e,list,result)}
                          className={`${isTopicVideo && "assets-search-box"}`}
                          value={searchInputValue}
                        />}
                      <IconButton className='search-btn' type="button" sx={{ p: '10px' }} aria-label="search" onClick={()=>handleSearch(result,list)} >
                        <SearchIcon className='search-icon' />
                        {(isloading && searchCrs <= 0) && <CircularProgress className='text-box-loader' />}
                      </IconButton>
                      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    </div>
                  </div>
                  {type === 'image' && <div className="col-lg-6">
                    <div className="image-gif btn-group" role="group" aria-label="Basic example">
                      <button type="button" className={`${!isGif && 'active '} btn btn-g`}onClick={handleImage}>Image</button>
                      <button type="button" className={`${isGif && 'active '} btn btn-g`} onClick={handleGif}>GIF</button>
                     </div>
                  </div>}
                </div>
              </div>
    </>
  }

  return (
    <div className="assets-container">
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="assets-model"
      >
        <Box sx={style}>
          <div className='assets-inner-container'>
          <div className='modal-header'><p className='header-text'>{isQuestionAsset ? 'Quiz Bank' : 'CE Asset Library'}</p> <span className='close-icon' onClick={modalClose}><CloseIcon /></span></div>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            className='assets-tab'
          >
          {renderTabs()}
          </Tabs>
          <TabPanel value={activeTab} index={0}>
            <div className="row outer-row">
               {assetsSearch(data,setAssetData)}
              <div className='col-lg-24'>
                <div className='row inner-row'>
                  {(isloading && searchCrs <= 0) && <div className='col-12 loading-area'><h2>Loading...</h2></div>}
                  {(assetData && !isTopicVideo && !isQuestionAsset) ? <>
                    <AssetsCard
                      data={assetData} handleSelection={(item) => contentSelection(item)}
                      type={type} isloading={isloading}
                      listType={'cardList'}
                    />
                    {/* Load More only for the video */}
                    {isShowMore && <div className='show-more-box'>
                      <Button onClick={()=>loadMoreItems(16)}>Load More Asset</Button>
                    </div>}
                  </>
                  : isQuestionAsset ?
                    <>
                      {assetData?.length > 0 && assetData?.map((item, index) => <div key={index} className='listing-card-quizzes' onClick={() => { setSelectedContent(item); setIsDrawer(true) }}>
                        <span style={{ marginLeft: '10px' }}>{`${index + 1}.  ${item?.question}`}</span></div>)}
                    </>
                    : <TopicVideoList
                      data={searchCrs}
                      handleSelection={contentSelection}
                      isloading={isloading}
                      archiveCrsList={archiveCourses} />}
                  {isDrawer && <AssetsPlayer
                    type={playerType} handle={
                      isQuestionAsset ? () => {
                        setSelectedContent(null)
                        setIsDrawer(false)
                      } :
                        () => { contentSelection([]) }}
                    content={selectedContent}
                    handleSubmit={(item) => handleSubmit(item)}
                  />}
                </div>
              </div>
            </div>
          </TabPanel>
         {hasClientAssets ? <TabPanel value={activeTab} index={1}>
          <div className="row outer-row">
               {assetsSearch(getClientList(),setClientData)}
              <div className='col-lg-24'>
                <div className='row inner-row'>
                  {(isloading && searchCrs <= 0) && <div className='col-12 loading-area'><h2>Loading...</h2></div>}
                  <AssetsCard
                      data={clientData} handleSelection={(item) => contentSelection(item)}
                      type={type} isloading={isloading}
                      listType={'cardList'}
                    />
                   
                </div>
              </div>
            </div>
          </TabPanel>:<></>}
           <TabPanel value={activeTab} index={hasClientAssets ? 2 : 1}>
            <Wishlist type={playerType} handle={(item) => setSelectedContent(item)} link={(item) => handleSubmit(item)} />
          </TabPanel>
          <div className='modal-bottom'>
            <Button type="button" className="btn model-cancel" onClick={modalClose}>Cancel</Button>
          </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}


AssetUploader.propTypes = {
  /** type of the assets */
  type: PropTypes.string,
  /** link Assets function  */
  asset: PropTypes.func,
  /** Selected Assets  */
  data: PropTypes?.array,
  /** Handle the model  */
  model: PropTypes.func,
}


export default AssetUploader
