import { useState, useEffect } from 'react';
import { Collapse } from 'react-collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useHistory } from 'react-router-dom';

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import "react-circular-progressbar/dist/styles.css";
import { useDispatch, useSelector } from 'react-redux';
import * as courseInfoAction from '../../../redux/actions/courseInfoAction'
import sideBarIcon from '../../../assets/img/Group_sidebar_logo.png';
import './linear.scss';
import Progress from '../Progress/Progress';
import PropTypes from 'prop-types'
import TopicAccordion from './TopicAccordion';
import { Tooltip } from '@material-ui/core';
import DataHelper from "../../../utils/DataHelper";
import { makeStyles } from '@material-ui/core/styles';
import t from '../../../translation/useTranslate';
import BuildHelper from "../../../utils/BuildHelper";

let depth = 0;

const useStyles = makeStyles((theme) => ({
  customStyle: {
      zIndex: "999999"
  },
}));

/**
 * Treemenu is the sidebar content will display the topic as tree levels
*/
const TreeMenu = ( { isOpen,elmentItem,navigationHandler,isHome } ) => {

  const classes = useStyles();
  const {courseInfo,globalStyle:{isLinear}} = useSelector( state => state);
  const trackInfo = useSelector(state => state.courseTracking);
  const [ms,setms] = useState(1000)
  const [activeSection, setActiveSection ] = useState(courseInfo.section.nodeId);
  const [progress,setCourseProgress] = useState(0);
  const [treeList,setTreeList] = useState([]);
  // let ms=trackInfo.courseTracking?.scorm?.ll?.split('^')[0];
  const dispatch = useDispatch();
  const history = useHistory();
  
  useEffect( () => {
    try{
        const flatten = courseInfo.flatten;
        trackInfo?.courseTracking?.scorm?.ll && setms((trackInfo?.courseTracking?.scorm?.ll?.split('^')[0] || Number(trackInfo?.scorm?.scormobj?.ttime) ));
        if(Object.keys(flatten)?.length > 0){
          /** Assuming ===> accordion node will  have flatten[el].lrncontent === null  
           *  Setting as treeList
           */
          let kList = Object.keys(flatten);
          let courseNode = [];
          if(kList.length > 0){
            kList.forEach((el)=>{
              if(flatten[el].lrncontent === null && flatten[el].children){
                courseNode.push(flatten[el])
              }
            });
            // setTreeList(courseNode);
            let items = [flatten[kList[0]]];
            setTreeList(items);
          }
         

          if(Object.keys(courseInfo.section).length === 0 ){
          // Setting first section active default
            setActiveSection(getFirstSectionNodeId(flatten));
          }
          findMaxDepth(courseInfo.flatten);
        }
    }catch(err){
      console.log("Error in TreeMenu",err)
    }
  }, [ courseInfo, trackInfo]);

   /**
 * 
 * @param {*} flatten 
 * Maximum depth of the tree item 
 */

    const findMaxDepth = (flatten)=>{
      for(var key in flatten){ 
          if(depth < flatten[key]?.nodeId?.length){
              depth = flatten[key]?.nodeId?.length
          }
      }
    }

  const getFirstSectionNodeId =(flatten)=>{
     let firstTp = getFirstTopic(flatten);
     if(Object.keys(firstTp).length > 0 ) {
       return firstTp.nodeId.split('.')[0]
     }
  }

  const getFirstTopic = ( flatten ) => {
    let klist = Object.keys(flatten);
    let fId = klist.find((key)=>{
    if( flatten[ key ].lrncontent && DataHelper.checkEmptyTopic(flatten[ key ].lrncontent) ) {
                 return key;
                }
        });
        return flatten[fId];
}


  useEffect( () => {
    if ( trackInfo.flatten ) {
      setTimeout(() => {
        let p = courseProgress(  courseInfo?.flatten || trackInfo?.courseTracking?.topic);
        setCourseProgress( p );
      }, 200)
    }
  }, [ trackInfo, courseInfo ] )

  const courseProgress = ( flatten ) => {
    let completed = 0;
    Object.keys( flatten ).forEach( ( key ) => {
      if (Number(flatten[ key ]?.percent) === 100 || Number(flatten[ key ]?.p) === 100) {
        completed++
      }
    } );
    return Math.round(( completed / Object.keys( flatten ).length ) * 100 );
  }

  const onToggle = ( acc, index ) => {
    setActiveSection( activeSection === acc.nodeId ? null : acc.nodeId );
    dispatch( courseInfoAction.selectedSection( acc ) );
  }

  const topicsCalculator = (fl) =>{
    let crs = {sec:0,tps:0 }
    for(var key in fl){
      if(fl[key].lrncontent  !== null && fl[key].lrncontent !== undefined){
        crs.tps++;
        if(fl[key].children){
          crs.sec++;
        }
      }
    }
    //console.log("***********************crs*********************************",crs);
    return crs.tps;
}

  // const total = Object.keys( courseInfo.flatten ).length;
  const total = topicsCalculator(courseInfo.flatten);
  const current = Object.keys( courseInfo.flatten ).indexOf( courseInfo.topic.nodeId )

  const goHome =()=>{
    history.push('/');
  }

  return <div className={`crs-menu tree-structure ${isLinear ? 'crs-linear' : 'crs-tree'}`}>
    <div className="title-info">
      <div className='title-topics'>
        {/* <div className='main-line vertical-border'></div> */}
        <span className='top-note-icon'><img src={sideBarIcon} alt="" /></span>
        {/* <h3 className={ `top-root-topics ${ isOpen }` } onClick={()=> goHome()}>{ courseInfo.courseAttributes.coursename }</h3> */}
        <h3 className={ `top-root-topics ${ isOpen }` } onClick={()=> goHome()} dangerouslySetInnerHTML={{ __html:courseInfo?.courseAttributes?.coursename || '' }} />
      </div>
      <div className='lesson-progress'>
      {isLinear ? <div className='slider-cprogress'>{t("CRS_PROGRESS")} <i className={ isOpen }>{t("TOT_SPENT")} :{ DataHelper.timeConvert(ms) } </i></div> : 
       
       <i className={ isOpen }>{t("LESSON")} { current } {t("OF")} { total }</i>}
        <span className='circular-progress'><Progress progress={progress}/></span>
      </div>
      {treeList.length > 0 && treeList.map( ( tp, index ) => (
        <div className={ activeSection === tp.nodeId ? 'accordion' : "accordion" } key={ tp.nodeId }>
          {/* <div className='vertical-border'></div> */}
          <div className={ activeSection === tp.nodeId ? 'active-acc-item acc-node' : "acc-node" }>
            <span className='acc-node-icon'>{Number(progress) !== 100 ? <CircularProgressbar
              value={ progress }
              className="circular-progress-icon"
              styles={ buildStyles( {
                rotation: 0.5 + ( 1 - 20 !== undefined ? 20 : 0 / 100 ) / 2
              } ) } /> :  <CheckCircleIcon className='check-circle-icon' /> }</span>
               <Tooltip title={tp.title} placement="right" classes={{ tooltip: classes.customStyle }}>
                <div className={ `title ${ isOpen }` } >{ tp.title }</div>
               </Tooltip>
            <button className={ `expand-menu-icon non-active-btn ${ isOpen } br-5` }  onClick={ () => onToggle(tp, index ) }>{ activeSection === tp.nodeId ? <ExpandLess /> : <ExpandMore />  }</button>
          </div>
          <Collapse isOpened={ activeSection === tp.nodeId ? true : false } >
          { tp.children && <TopicAccordion depth={depth} trackInfo={trackInfo} navigationHandler={navigationHandler} nodes={tp.children} isOpen={ isOpen } elmentItem={elmentItem} isLinear={isLinear} isHome={isHome} /> }
          {/* { tp.children && <TreeItem depth={depth} trackInfo={trackInfo} navigationHandler={navigationHandler} nodes={tp.children} isOpen={ isOpen } elmentItem={elmentItem} /> } */}
          </Collapse>
        </div>
      ) ) }
    </div>
  </div>
}



TreeMenu.propTypes = {
  /** Whether the sidebar is opened or not */
  isOpen: PropTypes.string
}

export default TreeMenu