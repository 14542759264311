import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import * as courseInfoAction from '../../../redux/actions/courseInfoAction';
import * as courseEditAction from '../../../redux/actions/courseEditAction';
import '../SearchLrnAids/searchlrnaids.scss';
import CommentIcon from '@mui/icons-material/Comment';
import PlayerConstants from '../../../utils/PlayerConstants';
import DataHelper from '../../../utils/DataHelper';
import MuiIcons from "../../../components/ui/MuiIcons/MuiIcons";
import AppButton from "../../../components/ui/AppButton/AppButton"
import useDate from '../../../utils/hooks/useDate';

/**
 * 
 * We have logs [-1, -2,-3] in the LRN, which indicate customized
 * -1: Customized LRN (applied during cloning)
 * -2: New LRN (created after cloning)
 * -3: LRN after applying the template
 * 1: newly added one(using + add)
 * [-1,-2,-3,7,1]- resolved (7,1 we have edit option)
 * 1- active
 * 6- ToDo
 */
const LearningAidComments = ({ data }) => {

    const dispatch = useDispatch()
    const { courseInfo: { flatten , topic} } = useSelector(state => state);
    const [preview, setPreview] = useState({});
    const [selected, setSelected] = useState({});
    const [searchResults, setSearchResult] = useState([]);
    const [selectedLElement, setSelectedLElement] = useState({});
    const [expanded, setExpanded] = useState(0);
    const [CrsList, setCrsList] = useState([]);
    const [cmtStatus, setCmtStatus] = useState(0);
    const [filteredResult, setFilteredResult] = useState([]);
    const { formatDate,fromNow} = useDate();

    const onTopicChange = (e) => {
        setSelected(e);
        const filtered = e?.logs?.filter(({ status }) => 
            Number(cmtStatus||5)===Number(status)
        );      
        setFilteredResult([...filtered])
        setSearchResult(e);
        setSelectedLElement({});
    }

    const openAcc = (el) => {
        if (expanded === el) {
            setExpanded(0)
        } else {
            setExpanded(el);
        }
    }

    const selectLearningElement = (lrn) => {
        setSelectedLElement(lrn);
        setPreview(lrn)
    }


    const renderLeaningElements = (lrains) => {
        return (<ul className='learing-element-list'>
            {lrains.map((lrn, index) => {
                return <li key={index} className={lrn?._id === preview?._id ? "flex-item selected-le-item" : "flex-item"} onClick={() => selectLearningElement(lrn.lrn)}>
                    {/* <div>{lrn._id} </div> */}
                    <div className='last-cmt'>
                    <span className='comp-list-icon'><img src={require("../../../assets/img/" +  (PlayerConstants?.COMP_ICONS?.[lrn?.lrn?.name] || 'topic_header.png') ).default} alt='' /></span>
                    {index+1}. {lrn?.comments?.replace(
                    'Copied from course for editing',
                    'Started customizing')} </div>
                    <div className='last-cmt-author'>{lrn?.name}</div>
                    <MuiIcons iconName={`${PlayerConstants.COMMENTS_STATUS[lrn?.status]?.status.toLowerCase()||'resolved'}_comments`} data-tooltip={PlayerConstants.COMMENTS_STATUS[lrn?.status]?.status||'Resolved'} data-position='left' color={PlayerConstants.COMMENTS_STATUS[lrn?.status]?.color || '#13984B' } className="p-0 pl-2 status-icon" />
                    <div className='last-cmt-date'>{formatDate(lrn?.modified, 'MMMM D, YYYY') } <strong className='fw-600'>{(fromNow(lrn?.modified))}</strong></div>
                </li>
            })}
        </ul>)
    }


    const topicSelected = (tp) => {
        try {
          // fetching curItem directly  flatten for comments add action to update it without cloning, ensuring we modify the original object
            let curItem =flatten[selected?.nodeId]?.lrncontent?.find( item => { return item?._id === tp?._id } );
            dispatch(courseEditAction.toggleDrawer({ open: false, drawerWidth: 0 }))
            dispatch(courseInfoAction.selectedTopic(selected));
            if(curItem){
                setTimeout(() => {
                    dispatch(courseEditAction.toggleDrawer({ open: true, target: {}, itemInfo:curItem, panel: 1, drawerWidth: 25 }))
                    DataHelper.scrollToLrnElement(tp);
               }, [1000])
            }
        } catch (e) {

        }
    }
/**
 * Filter logs to include only active ones, excluding system-generated entries.
 */
    useEffect(() => {
        try {
            const flattenCRS = Object.keys(flatten)?.map((key) => {
                let logs = [];
                let activeLogs=[]
                // const aidTracker = {};
                //const hasConfig=!DataHelper.isCourse()
                flatten[key]?.lrncontent?.forEach((el) => {
                    let lrn = {...el};
                    let currentLogs = el?.logs ?? [];
                    let currentAcLogs=[]
                    if(currentLogs?.length > 0){
                        currentLogs= currentLogs?.map(e=>{return{...e,lrn:lrn}});
                         currentAcLogs = currentLogs?.filter(({status}) => [5, 6, 7].includes(status));
                    }
                    activeLogs=[...activeLogs,...currentAcLogs]
                    logs = [...logs,...currentLogs];

                    // if (lrn?.logs?.length > 0) {
                    //     if (aidTracker[lrn?.name]) {
                    //         aidTracker[lrn?.name].lrnaids.push(lrn);
                    //     } else {
                    //         aidTracker[lrn?.name] = { name: lrn?.name, lrnaids: [lrn] };
                    //     }
                    // }
                });

                // Sort logs based on the 'modified' date in descending order
                 logs = logs?.sort((a, b) => new Date(b?.modified) - new Date(a?.modified));

                // const lrnList = Object.values(aidTracker);
                return { ...flatten[key], logs,activeLogs  };//lrnList
            })?.filter(item => item?.activeLogs?.length > 0);
    
            setCrsList(flattenCRS);
        } catch (error) {
            console.error(error);
        }
    }, [flatten]);
    
    const handleStatusFilter=()=>{
        let statusIds=["7",'5','6'];
               // Find the current index of the previous status ID
       const currentIndex = statusIds.indexOf(cmtStatus||'5');
       // Calculate the next index and wrap around using modulus
       const nextIndex = (currentIndex + 1) % statusIds.length;
       setCmtStatus(statusIds[nextIndex]);

       const filtered = searchResults?.logs?.filter(({ status }) => 
        Number(statusIds[nextIndex]) ===  Number(status));      
       setFilteredResult([...filtered])
       setSelectedLElement({})
    }
    
    

    return (
        <div className='lrn-comment-container lrnaid-search-panel px-4'>
            <div className='header-comments'>
            {searchResults?.logs?.length > 0 && 
             <AppButton style={{background:PlayerConstants?.COMMENTS_STATUS[cmtStatus||'5']?.color, minWidth:'120px'}} theme="primary1" className="mb-2" onClick={handleStatusFilter}>{PlayerConstants.COMMENTS_STATUS[cmtStatus||'5']?.status} <MuiIcons iconName='filter' color={"#fff"}  className="p-0 pl-2 status-icon" /></AppButton>
            }
           
            </div>
            <div className='row'>
                <div className='col-lg-6 col-md-6 col-sm-6 p-0'>
                {CrsList.length > 0 ? <ul className='courselist'>
                        { CrsList.map((el, index) =>{
                            let isComments=el?.logs?.length > 0
                            return <li key={index} className={(el?.id) === selected?.id ? 'course-li-active course-li' : `course-li ${el?.id}`} onClick={() => onTopicChange(el, index)}> {el?.title}  <span><CommentIcon  className={(isComments) ? 'active-cmt cmt-tp' : 'cmt-tp'}/></span></li>
                        })}
                    </ul> : <p className='mx-2 fw-bold'>Comments not found.</p>}
                </div>

                <div className='col-lg-6 col-md-6 col-sm-6 topic-header'>
                    {/* Preview conatiner start*/}
                    {
                        (selectedLElement?.logs?.length > 0) && Object.keys(selectedLElement).length > 0 ?
                            <div className='preview-container cmd-preview'>
                                <div className='preview-head'>
                                    <h6 className='preview-title'>{`${PlayerConstants?.LRN_RE_NAME[selectedLElement?.name] ??selectedLElement.name}`}-<p className='cmt-last-update'>(Last Updated on - {formatDate(selectedLElement?.logs[selectedLElement?.logs?.length - 1]?.modified,'MMMM D, YYYY')} {fromNow(selectedLElement?.logs[selectedLElement?.logs?.length - 1]?.modified)})</p></h6>
                                    <div>
                                        <span className='lrn-cmd-edit'  onClick={() => topicSelected(selectedLElement)}><EditIcon className="tp-edit" />Edit</span>
                                        <CloseIcon className='close-pre' onClick={() => { setSelectedLElement({}) }} />
                                    </div>
                                </div>  
                                <div id='preview'>
                                    {selectedLElement?.logs.length > 0 && selectedLElement?.logs.map((item, index) => {
                                        return <div className='author-comment-section' key={item._id.toString()}>
                                            <div className='comment-row row'>
                                                <div className='profile-badge col-md-1'>
                                                <span className='profile-latter'>{DataHelper.getInitials(item?.name)}</span>
                                                    {/* <img src={require('../../../assets/img/profile_icon.png').default} alt="" className='badge-tag' /> */}
                                                </div>
                                                <div className='col-md-5'>
                                                    <p className='profile-name '>{item?.name}</p>
                                                </div>
                                                <div className='col-md-1'>
                                                <MuiIcons iconName={`${PlayerConstants.COMMENTS_STATUS[item?.status]?.status.toLowerCase()||'resolved'}_comments`} data-tooltip={PlayerConstants.COMMENTS_STATUS[item?.status]?.status||'Resolved'} data-position='left' color={PlayerConstants.COMMENTS_STATUS[item?.status]?.color || '#13984B' } className="p-0 pl-2 status-icon" />
                                                </div>
                                                <div className='profile-comment col-md-4'>
                                                    <p className='modified-date'>{formatDate(item.modified,'MM/DD/YYYY h:mm')} </p>
                                                </div>
                                                <div className='col-md-1'></div>
                                                <div className='comt-preview col-md-10'>
                                                    {item?.comments?.replace(
                                                        'Copied from course for editing',
                                                        'Started customizing')}
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                                {/* Preview container end */}
                            </div>
                            : (filteredResult?.length > 0) ? renderLeaningElements(filteredResult) :( searchResults?.logs?.length > 0 && <h4>Comments not found.</h4>)
    
                    }
                    {(Object.keys(selected).length > 0 && searchResults?.logs?.length < 1) && <div><p>Comments not found.</p></div>}
                </div>
            </div>

        </div>
    )
}

export default LearningAidComments