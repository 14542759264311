import React, { useEffect, useState } from 'react'
import './LearningAidList.scss'
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import AddIcon from '../../../assets/img/content_plus.png'
import CancelIcon from '../../../assets/img/content_cancel.png'
import PropTypes from 'prop-types'
import TEMPLATE_MODEL from '../../../utils/TemplateModel'
import LearnAidItem from '../../../utils/LearnAidItem'
import { useDispatch, useSelector } from 'react-redux';
import * as courseEditAction from '../../../redux/actions/courseEditAction'
import * as courseInfoAction from '../../../redux/actions/courseInfoAction'
import ContentTracking from '../../../core/progress/ContentTracking';
import DataHelper from '../../../utils/DataHelper';
import PlayerConstants from '../../../utils/PlayerConstants';
import ACLHelper from '../../../utils/ACLHelper';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import BuildHelper from '../../../utils/BuildHelper';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

/*
  this component will show the list of content components to the user which might user can easily have a glance to edit
*/

const LearningAidList = ({ cmpName,compId }) => {
    const dispatch = useDispatch();
    const {courseInfo,courseEdit} = useSelector(state => state);
    const userInfo = useSelector(state => state.userInfo);
    const trackInfo = useSelector(state => state.courseTracking);
    const classes = useStyles();
    const [isOpen, setOpen] = useState(false)
    const [compList, setCompList] = useState([]);

    /** Handling default opening of list */
    useEffect(() => {
        setOpen(false)

    }, [compId])

    useEffect(() => {
        console.log("courseInfo", courseInfo);

    }, [courseInfo])

    const handleToggle = () => {
        setOpen(!isOpen)
    }

    const isConfig = () => {
        return window?.ce?.ceuser?.type === 'config' ? true : false
    }

    useEffect(() => {
        try{
        let list = TEMPLATE_MODEL;
        let mapped = [];

        // id is temp here need to find correct one replace it
        /**
         *  const lrnaid_status_enum = [
                { id: 2, status: "In-Progress" },
                { id: 3, status: "Under review" },
                { id: 1, status: "Approved" },
                { id: 0, status: "Canceled" }
            ]
         * Newly added LearningAid status should in progress-1
         *  refer this PlayerConstants.STATUS_LRN_AIDS
         */
        let tempRemoveList = ['8','10', '11', '12', '13', '20']  //Just add the temporary removal of ids
        if(isConfig())tempRemoveList=[...tempRemoveList,'22'] // removed (13/08/24) based on Kim's request
            
         // only kim right now
         if(PlayerConstants?.ARCHIVE_VIDEO_ENABLE_LIST?.includes(userInfo?.user?.email||''))tempRemoveList=tempRemoveList?.filter(item => item !== '20')
        for (var key in list) {
            if(tempRemoveList.indexOf(key) === -1){
                // By default, the status is set to 1 ("approved") as per Kim's request on 23/08/24.(-1 to 1)
                let obj = { ...list[key], id: Number(key), status:1};
            // /* LegacyInteractivity Added */   
            // if(Number(key)===22){
            //     if(courseEdit?.legacyCrcStructure.length <= 0) dispatch(courseEditAction.requestCrcStructure(PlayerConstants?.LEGACY_INTERACTIVITY));
            //     mapped.push(new LearnAidItem({...obj,name:"LegacyInteractivity"}));
            // } 
                mapped.push(new LearnAidItem(obj))
            }
        }

        /** Configuration Archive video should not be there */

        if (isConfig()) {
            mapped = mapped.filter((aid) => {
                return aid.name !== 'ArchiveVideo'
            })
        }

        setCompList(mapped);
    }catch(error){
        console.error("LearningAidList.js issue : ",error)
    }

    }, [userInfo]);

    const selectContent = (el) => {
        switch (Number(el?.id)) {
            // case 20:
            //     dispatch(courseEditAction.toggleDrawer({ open: true, panel: 6, itemInfo: { name: "ArchiveVideo", compId: compId }, drawerWidth: 80 }));
            //     break;
            case 22:
                dispatch(courseEditAction.toggleDrawer({ open: true, panel: 9, itemInfo: { name:el.name==="Search" ? "Search" : "Legacy interactivity", compId: compId }, drawerWidth: 80 }));
                break;
            default:
                try {
                    let found = compList.filter((e) => {
                        return e.id === el?.id
                    })[0]
                    // console.log("Selected Learning Aid From Lits ======>",found);
                    addLearningAidItem(found);
                    setOpen(!isOpen)
                } catch (e) {
                    console.log(e);
                }

                break;
        }   
    }

        // updating publish latest data in courseEditingInfo reducer
        const updateLatestPublish=(val)=>{
            dispatch(courseEditAction.publishCourseInfo(val))
        }


    /** 
     * Adding section and concat with exisiting markup 
     * compid will be lrncontent length 
     * 2 API has to used to differntciate type = "course" && type == config
     * 
     *  search learning aid add Specific Handling for Quizzes:
     * - When a quiz learning aid is added, it requires special processing because quizzes have a separate quiz bank.
     * - During both publishing and normal additions, quiz items are removed and replaced with the quiz question `_id` due to this separation.
     * 
     */
    const addLearningAidItem = (lrnData) => {
        try {
            let aidItem=DataHelper.deepClone(lrnData);
            let tp = courseInfo?.topic;
            let markup = tp?.markup;

            const newLrn = DataHelper.getNewLrn(compId, tp.markup, aidItem)
            aidItem.compid = newLrn.cmpid;
            console.debug("newLrn",newLrn)
            let id = 0
            if (newLrn.id) {
                id = newLrn.id
            }
            if (aidItem?.props) {
                /**TODO cid*/
                let cid = PlayerConstants.getComponentByName(aidItem?.name).id + ":" + aidItem.compid;
                console.debug("cid", cid)
                let trackobj = new ContentTracking(cid, 0, {})
                aidItem.props.track = trackobj;
            }

            // tp.markup = newLrn.markups; 
            // tp.lrncontent.push(aidItem);

            // console.debug(aidItem,"<=============topic =================>",newLrn);

             if(DataHelper?.isPublish()){
                let {existingLrnCollectionAdd, ...lrnItem}=aidItem
                let payload={
                    payload:Object.assign(newLrn, { lrnItem: lrnItem, topic: tp, topicid: tp.id, author: userInfo.user.uid }),
                    publish:{lrnItem,courseEdit,courseInfo,trackInfo,updateLatestPublish},
                    existingLrnCollectionAdd:existingLrnCollectionAdd
                }
                dispatch(courseEditAction.getPublishCourseLRNId({...payload}));
                return;
             }
             let {existingLrnCollectionAdd, ...lrnItem}=aidItem;
             if(existingLrnCollectionAdd){
                lrnItem.props.items= lrnItem.props.items.map((e)=>e?._id||e)
             }
             let payload={ lrnItem: lrnItem, topic: tp, topicid: tp.id, author: userInfo.user.uid }
             if(BuildHelper.isConfig()){
                payload.clientid=window?.ce?.ceuser?.pvtid;
              }
            dispatch(courseEditAction.addNewLearningAid(Object.assign(newLrn, { ...payload })));
        } catch (e) {
            console.log(e);
        }
    }


    /**
     * This useEffect handles the addition of a search learning aid when `courseEdit?.existingLrnAdd` changes.
     * 
     * Specific Handling for Quizzes:
     * - When a quiz learning aid is added, it requires special processing because quizzes have a separate quiz bank.
     * - During both publishing and normal additions, quiz items are removed and replaced with the quiz question `_id` due to this separation.
     * 
     * Workflow:
     * - If the learning aid is a quiz (`lrnName === "Quizzes"`), it sets `existingLrnCollectionAdd` to true.
     * - The `existingLrnCollectionAdd` flag is true, inside the middleware (index.js), the quiz items is replacing to _id
     */
    useEffect(()=>{
        try{
            let addedLrn=courseEdit?.existingLrnAdd
            if(addedLrn?.props){
                let found = compList.filter((e) => {
                    return e.name === addedLrn?.name
                })[0];
                let lrnName=addedLrn?.cename||addedLrn?.name;
                let updatFound={
                    ...found,
                    props:addedLrn?.props,
                    name:lrnName,
                    existingLrnCollectionAdd:(lrnName==="Quizzes")
                }
                addLearningAidItem(updatFound);
                dispatch(courseEditAction.addExistingLearningAid({}))
            }
        }catch(e){}
    },[{...courseEdit?.existingLrnAdd}])

    // const getLearningAidIcon = (index) => {
    //     try {
    //         return require(`../../../assets/img/${compIcon[index]}`).default
    //     }
    //     catch (e) {
    //         console.log(e);
    //     }
    // }


    const sortAidsByOrder = () => {
        try {
            const list = compList.sort((a, b) => (PlayerConstants.LRN_RE_NAME[a?.name]||a?.name).localeCompare((PlayerConstants?.LRN_RE_NAME[b?.name]||b?.name)))
            return list
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleButtonClick = (param) => {
        let tp = courseInfo?.topic;
        let markup = tp?.markup;
        let splitData = markup?.split('</section>');
        let temp = splitData.filter(item => item.trim().length > 0).filter(item => {
            const cmprndr = tp.lrncontent.find(res => {
                return Number(res.compid) === Number(item.slice(item.lastIndexOf("com_") + 4, item.lastIndexOf(">") - 1))
            })
            return cmprndr
        })
        const tempIndex = temp?.findIndex(item => {
            return item.slice(item.lastIndexOf("com_") + 4, item.lastIndexOf(">") - 1) == compId
        })
        let temp1 = temp[tempIndex];
        if (temp.length > 1) {
            if (param === "up") {
                if (tempIndex === 0) {
                    const tempArray = temp.filter((item, index) => index != tempIndex)
                    JSON.parse(JSON.stringify(tempArray))
                    tempArray.push(temp1)
                    temp = tempArray
                } else {
                    let swapItem = temp[tempIndex - 1]
                    temp[tempIndex - 1] = temp1
                    temp[tempIndex] = swapItem
                }

            } else if (param === "down") {
                if (tempIndex === temp.length - 1) {
                    const tempArray = temp.filter((item, index) => index != tempIndex)
                    temp = [temp1, ...tempArray]
                } else {
                    let swapItem = temp[tempIndex + 1]
                    temp[tempIndex + 1] = temp1
                    temp[tempIndex] = swapItem
                }
            }
            let updatedMarkUpList = temp?.filter(item => item?.trim().length > 0).join('</section>')+'</section>';
            const payload = {
                nodeId: tp.nodeId,
                customtemplate: updatedMarkUpList,
                markups: updatedMarkUpList,
                id: courseInfo.topic.id,

            }
            dispatch(courseInfoAction.updateMarkup(Object.assign({}, payload)));
            if(DataHelper?.isPublish()){
            
                DataHelper.publishFormatting({},courseEdit,courseInfo,trackInfo,updateLatestPublish,{type:5,markup:updatedMarkUpList})
                return;
             }
            dispatch(courseInfoAction.editTopic(Object.assign({}, payload)));
        }
    }
    // let tp2 = courseInfo?.topic;
    // let markup1 = tp2?.markup;
    return <div className='content-list-container'>
        <div className='content-list-main'>
            <div className='content-components'>
                <div className={`${classes.root} content-component-list ${!isOpen ? 'list-hidden' : ''}`}>
                   <List className='add-Widgets-title'><ListItem >Content Widgets</ListItem></List>
                    <List component="div" aria-label="main mailbox folders">
                        {(Object.values(sortAidsByOrder())).map((el, index) => <div key={index}> <ListItem button onClick={() => selectContent(el)}>
                            <img className='add-comp-list-icon' src={require("../../../assets/img/" +  (PlayerConstants?.COMP_ICONS?.[el?.name] || 'topic_header.png') ).default} alt={PlayerConstants.LRN_RE_NAME[el?.name]||el?.name} />
                            <ListItemText primary={PlayerConstants.LRN_RE_NAME[el?.name]||el?.name} className='list-label-text' />
                        </ListItem>
                            <Divider /> </div>)}
                    </List>
                </div>
            </div>
            <div className='content-pop-icon' onClick={() => handleToggle()}>
                {isOpen ?
                    <span className='add-cancel-icon'><img src={CancelIcon} alt="" /></span> :
                    <>{ ACLHelper.isFeatureEnabled(1,1) && <span className='add-circle-icon'><img src={AddIcon} alt="" /></span>}</>
                }
            </div>
        </div>
        <div className="arrows">
            <div className='arrow-upward' onClick={() => handleButtonClick("down")}><ArrowDownwardIcon /></div>
            <div className='arrow-downward' onClick={() => handleButtonClick("up")}><ArrowUpwardIcon /></div>
        </div>
    </div>
}

/*
    passing value to the default props that can feed to the component
*/

// LearningAidList.defaultProps = {
//     compIcon: [
//         'topic_header.png',
//         // 'Video_comp.png',
//         'Accordion_comp.png',
//         'Audio_comp.png',
//         'topic_header.png',
//         'Course_Image_Flip.svg',
//         'Carousel_comp.png',
//         "Image_text.png",
//         'drag_and_match.png',
//         'Draggables_comp.png',
//         'Draggables_comp.png',
//         'Flip_card.svg',
//         'Course_Image_Flip.svg',
//         "Image_text.png",
//         'ImageSelect_comp.png',
//         'Imagemapping_comp.png',
//         'Quizzes_comp_.png',
//         // 'Carousel_comp.png',
//         'Quizzes_comp_.png',
//         'Carousel_comp.png',
//         'Quizzes_comp_.png',
//         'Quotes.svg',
//         'Quizzes_comp_.png',
//         'Scenario.svg',
//         'search.png',
//         'Tab_comp.png',
//         'Topicdesc_comp.png',
//         'Video_comp.png',
//     ]
// }

LearningAidList.propTypes = {
    /** array of components to show as list menu */
    compArr: PropTypes.array
}

export default LearningAidList
