import React, { createRef, useEffect, useState, useRef } from 'react';
import './layout.scss';
import ScrollLayout from './ScrollLayout';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as courseInfoAction from '../../redux/actions/courseInfoAction'
import * as courseTrackingAction from '../../redux/actions/courseTrackingAction'
import * as utilityAction from '../../redux/actions/utilityAction';
import * as courseEditAction from '../../redux/actions/courseEditAction'
import TreeMenu from '../../components/ui/TreeMenu/TreeMenu';
import hotkeys from 'hotkeys-js';
import HoverHighlighter from '../../editor/components/highlighter/HoverHighlighter';
import EditDrawer from '../../editor/components/EditDrawer/EditDrawer';
import NavBar from '../../components/ui/NavBar/NavBar';
import ACLHelper from '../../utils/ACLHelper';
import DataHelper from '../../utils/DataHelper';
import QuickTour from '../../components/ui/QuickTour/QuickTour'
import Loader from '../../components/ui/Loader/Loader';
import CommonModal from '../../components/ui/CommonModal/CommonModal';
import useStorage from '../../utils/hooks/useStorage';
import { FormControlLabel} from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import ScrollButton from '../../components/ui/ScrollButton/ScrollButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BuildHelper from '../../utils/BuildHelper';
import AppButton from '../../components/ui/AppButton/AppButton';
import t from '../../translation/useTranslate';
import useToastNotifications from '../../utils/hooks/useToastNotifications';
import CustomCheckbox from "../../components/ui/CustomCheckbox/CustomCheckbox"
// import { DndProvider } from 'react-dnd';
// import { HTML5Backend } from 'react-dnd-html5-backend';

const Layout = () => {
  const dispatch = useDispatch()
  const sideBarRef = createRef();
  const headerRef = createRef();
  const scrlRef = createRef();
  const courseInfo = useSelector(state => state.courseInfo);
  const courseList = Object.values(courseInfo.flatten);
  const trackInfo = useSelector(state => state.courseTracking);
  const userInfo = useSelector(state => state.userInfo);
  const utility = useSelector(state => state.utility);
  const globalStyle = useSelector(state => state.globalStyle);
  const ceAccetsInfo = useSelector(state => state.ceAssetsInfo);
  const courseEdit = useSelector( state => state.courseEdit );
  // const scorm=trackInfo?.courseTracking?.scorm;
  let ctour = window.localStorage.getItem('course-tour');
  let isGlobalEdit = courseEdit?.isOpen && courseEdit?.panel === 10 && 'editpanel-space'
  const history = useHistory();
  const [expandTree, setTree] = useState(false)
  const [viewInDevice, setViewDevice ] = useState({})
  const { showSuccess, showError } = useToastNotifications();

  const [viewState, setViewState] = useState({ viewport: { name: 'Desktop' }, isHighLight: true });
  const [isLastTopic, setIsLastTopic] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  // Using this storage for a one-time popup after completing the course
  const configId = window?.ce?.rlm?.config_id || "1";
  const isLearner =Number(window?.ce?.ceuser?.role_id) === 4;
  const [attestationCheck, setAttestationCheck] = useState(false);
// pass the keys array or object to the hook
  const storageKeys = [`${configId}`];
  /**
   * global setting: Check if the "text not active" setting is enabled
   * -1 not active
   * 1 active Attestation
   * 2 active Print certificate completed view 
   * 3 completed print certificate page
   * 4 declined
   * 
   */
  const getAttestation=((BuildHelper.isConfig() || BuildHelper.isOnScorm()) && globalStyle?.attest?.is && !globalStyle?.attest?.begin)? "1": "2";
  const storage = useStorage({
    keys: storageKeys, // Use 'keys' instead of 'key'
    defaultValue:{
      mail:"",
      attestation:"-1"
    },
    storageType: 'session', // or 'local'
  });
  let crsStorage =storage &&  storage[`${configId}`]?.get();



  useEffect(()=>{
    if(Object.keys(userInfo?.acl).length > 0 ){
      ACLHelper.initialize(userInfo?.acl,userInfo?.user?.role_id);
    }
  },[userInfo?.acl])
 // Optimization: Avoiding unnecessary state updates and re-renders
//  useEffect(()=>{
// setTimeout(() => { setReRender(!reRender) }, 100)
//  },[courseInfo])
  const goHome = () => {
    const sideBar = sideBarRef.current;
    sideBar.classList.toggle('home-route');
    if (headerRef) {
      classHelper(headerRef)
    }
    if (scrlRef) {
      classHelper(scrlRef)
    }
    setTimeout(() => {
      history.push('/');
    }, 800);
  }



  /** On Next topic always scroll to top */
  const scrollTop = () => {
    scrlRef.current.scrollTo({ top: 100, behavior: 'smooth' });
  }

  const classHelper = (el) => {
    if (el && el.current)
      el.current.classList.toggle('hidden');
  }

  const goToNextTopic = (obj, isPrev) => {
    // if(obj.nodeId === DataHelper.getLastTopic(courseInfo?.flatten)?.nodeId && !DataHelper.isAllTpCompletedExceptLast(courseInfo?.flatten)){
    const lastNode=DataHelper.getLastTopic(courseInfo?.flatten)?.nodeId;
    const hasAllTpCompletedExceptLast=DataHelper.isAllTpCompletedExceptLast(courseInfo?.flatten,trackInfo?.courseTracking);
    const hasLearner=BuildHelper.isLearner();
    if(obj.nodeId === lastNode && !hasAllTpCompletedExceptLast  && hasLearner && obj?.lrncontent?.length !== 0){
      setIsLastTopic('last-topic')  
      return 
    }

    /**
    * 
    * We use strict navigation in global settings to control navigation based on completion. 
    * This ensures that users can only proceed to the next step after completing the current one. 
    * 
    */
     const currentTopic= courseInfo?.topic||{}
    if(( !DataHelper.isCurrentTopicCompleted(currentTopic) && !isPrev && globalStyle?.snc) && currentTopic.nodeId !=='1' && hasLearner) {
      setIsLastTopic('strict-nav')  
      return 
    }
    const isCompleted = (courseInfo?.flatten[1]?.p==100 || courseInfo?.flatten[1]?.status==1);
    if(!isCompleted && trackInfo?.courseTracking?.scorm ) {
       dispatch(courseTrackingAction.saveCourseProgress(trackInfo))
    }

    scrollTop();
    dispatch(courseInfoAction.selectedTopic(getTpWithContent(obj, isPrev)));
    /**
     * On click of next if of all component progress is 100 
     * then set topic percentage to 100
     */
    /**PROG TEST*/

    try {
      navigationEventHandler({ type: 'BTM_NXT', node: getTpWithContent(obj, isPrev) })
    } catch (e) {
      console.log(e);
    }
  }
  const getTpWithContent=(node, isPrev)=>{
  try{

    // helper function to recursively find a node with non-empty lrncontent
    const findNonEmptyContentNode = (currentNode, isPrev) => {
      // return null if the current node is invalid
      if (!currentNode) return null;

     // check if the current node's lrncontent is non-empty and valid
      if (currentNode.lrncontent?.length > 0 && DataHelper.checkEmptyTopic(currentNode.lrncontent)) {
        return currentNode;
      }

    // if the current node has children, recursively check each child
      if (currentNode?.children) {
        for (const child of currentNode?.children) {
          const result = findNonEmptyContentNode(child, isPrev);
          if (result) {
            return result;
          }
        }
      }

      return currentNode;
    };
  // Initial check for the current node
  if (node?.lrncontent?.length > 0 && DataHelper.checkEmptyTopic(node.lrncontent)) {
    return node;
  }

  // If the current node is empty and not a 'previous' action, check its children
  if (node?.children && !isPrev) {
    for (const child of node.children) {
      const result = findNonEmptyContentNode(child, isPrev);
      if (result) {
        return result;
      }
    }
  }
   // If the node has children but 'isPrev' is true return node
  if(node?.children){
    return node?.children.find((el)=>{
      return el?.lrncontent
   })
  }

 // return the current node if no other conditions are met
  return node;
   
  }catch(e){
    console.log("error",e)
  }
}

  /**
   * 
   * @param {type,node} data 
   * this event is passed to Tree and to TreeItem from there
   * event type and topic node
   * checks whether the first time or visited
   * handling this in a common place to trigger LRS
   * 
   * visited data saving sent to server only when the topic navigates
   * if visisted a current place and close the course will not save anything as of now.
   */
  const navigationEventHandler = (data) => {
    if (trackInfo.courseTracking && trackInfo.courseTracking?.topic && trackInfo.courseTracking.topic.length > 0) {
      try {
        let topicTracking = trackInfo?.courseTracking?.topic?.find(item => { return item.topicId === data.node.id });
        if (topicTracking.status === -1) {
           topicTracking.status = 0;

        }
        else if (topicTracking?.status === 0 && userInfo.user.role_id === 4) {
          let stmetObj = { id: courseInfo.topic.id, file: 'RLM Topic', name: courseInfo.topic.title,ln_id:trackInfo?.location_id||'0', lrn_type:'0'};
          // console.log('Revisted ', data.type, '....',courseInfo.topic)
          trackInfo?.lrs.trackActivity('topic', -1, stmetObj)
        }
        else {
          // Done state  set to LRS from ScrollLayout
        }

      } catch (error) {

      }
    }
  }

  const uiNavigationHandler = (state) => {
    // console.log(state.data)
    switch(state.type)  {
      case 'NAV_GO_HOME':
          goHome();
      break;
      case 'NAV_RESPONSIVE_VIEW':
        let vp = {...viewState,viewport:state.data.view, isHighLight:(state.data.view.name === 'Desktop' || false)};
        setViewState(vp)
      break;
    }
  }

  const handleScroll = e => {
    let element = e.target
    // addAnimationClass(element)
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      // console.log('------------, Reached bottom')
    }
  }


  useEffect(() => {
    if (window.innerWidth < 1100 || globalStyle?.theme==="skill_theme") {
      dispatch(courseInfoAction.setSideBar(false))
    }
    // completedTopic()
  }, [dispatch]);


  const isCourseCompleted = () => {
    try{
      let flatten = courseInfo?.flatten;
      const crsNode = Object.keys(flatten)[0]
      if (flatten[crsNode]) {
        const completed =DataHelper.checkIfAllChildrenCompleted(flatten[crsNode]);
        // if(completed){ handleAttestation(1);} 
          return completed
      }
      return false;
    }catch(e){
      console.error(e);
      return false; 
    }
  }

  // memoize the function to avoid unnecessary function call
   const memoizedIsCourseCompleted = React.useCallback(isCourseCompleted, [courseInfo?.flatten]);

  //memoized function 
    const nextButtonStyle = {
      backgroundColor: memoizedIsCourseCompleted() || isEnd ? "#13984B" : "grey",
    };

    /** Publish socket connection check */
    useEffect(() => {
    // course lock check
    if(trackInfo?.courseTracking?.lock_course || utility?.authFault  || Number(utility?.socketWarning) === 1){
       history.push( `/` );  
    }
    },[utility?.socketWarning,trackInfo?.courseTracking?.lock_course ]);

  
  /**
   * 
   * @returns {number} 1 for attestation 1, 2 for attestation 2, 3 for attestation 3, 4 for attestation 4
   * based on raw score disable and enable the attestation
   */
  const scormTrack =()=>{
    if(BuildHelper.isOnScorm()){
      let hasScore=trackInfo.scorm.getScore();
      handleAttestation(getAttestation);
      if(Number(hasScore)===100){
        handleAttestation(3);
        return;
      }
      handleAttestation(1);
    }
  }


/**
 *course completion notification and popup handling
*/

useEffect(() => {
  if(!isLearner) return;
  let flatten = courseInfo.flatten;
  if (!courseInfo.nav.next?.title || Number(flatten['1']?.status)===1|| Number(flatten['1']?.p)===100 && crsStorage.attestation !=='3') {
          try {
            const crsNode = Object.keys(flatten)[0]
            if (flatten[crsNode]) {
              const isCom = DataHelper.checkIfAllChildrenCompleted(flatten[crsNode]);
              if (isCom && crsStorage.attestation !=='4') { 
                //updating the scorm 
                scormTrack();
                handleAttestation(getAttestation); 
                notifyCompletion();
                setIsEnd(true);
              }
            }
          } catch (e) {
            console.log(e);
      }
  }  
}, [trackInfo,courseInfo])

  const setCompLmsData = () => {
      handleAttestation(getAttestation)
      dispatch(courseTrackingAction.saveCourseProgress(trackInfo))
      // setCompletion(true);
      setIsLastTopic('CRS_COM_SUCCESS');
      return;
  }


  const hotkeysConfig = hotKeyFunObj(dispatch, trackInfo);
  /** 
   * enableHotkeys: Binds hotkeys defined in the configuration.
   * It maps key combinations to specific actions for shortcuts.
   */
  const enableHotkeys = (hotkeysConfig) => {
    hotkeysConfig.forEach(({ keyCombo, action }) => {
      hotkeys(keyCombo, function (event, handler) {
        if (handler.key === keyCombo) {
          action();
        }
      });
    });
  };

  const applyViewInDevice = (style) => {
    console.debug('Device Applied ', style)
    setViewDevice(style)
  }

  const toggleExpand = () => {
    setTree(!expandTree)
  }

  useEffect(()=>{
   if((Number(window?.screen?.availWidth) < 900) && courseInfo?.sideBar){
      setTree(true);
   }
  },[courseInfo.sideBar])


  const getFirstTopic = ( flatten ) => {
    let klist = Object.keys( flatten );
    let fId = klist.find( ( key ) => {
      if ( flatten[ key ].lrncontent && DataHelper.checkEmptyTopic(flatten[ key ].lrncontent) ) {
        return key;
      }
    } );
    return flatten[ fId ];
  }

  const isFirstTopic = (tp) =>{
    try{
       return getFirstTopic(courseInfo?.flatten).nodeId === tp.nodeId ? true:false
    }catch(e){
      return false
    }
  }

// Workflow: Enable hotkeys on component mount and rebind on dependency change (courseList, courseInfo, trackInfo). 
// Unbind hotkeys on component unmount to prevent memory leaks or lingering hotkey bindings.
  useEffect(()=>{
    enableHotkeys(hotkeysConfig);
     /** 
       * Cleanup: Unbind all hotkeys when the component is unmounted
      */
    return () => {
      hotkeysConfig.forEach(({ keyCombo }) => hotkeys.unbind(keyCombo));
    };
  },[courseList, courseInfo, trackInfo]);


  useEffect(() => {
  
    const handleTopicMessages = () => {
      switch (isLastTopic) {
        case 'last-topic':
          showError(t("LAST_TOPIC_MSG"));
          break;
        case 'strict-nav':
          showError(t("STRICT_TOPIC_MSG"));
          break;
        case 'CRS_COM_SUCCESS':
          showSuccess(t("CRS_COM_SUCCESS"));
          break;
        default:
          break;
      }
      setIsLastTopic(false);
    };
  
    const handleUtilityMessages = () => {
      if (utility?.tplAssigned) showSuccess('Template Assigned Successfully');
      if (utility?.lAidAdded) showSuccess('LearningAid Added Successfully');
      if (utility?.lAidDeleted) showSuccess('LearningAid Removed Successfully');
      if (utility?.isSavedTp) showSuccess('Saved as Template Successfully');
      if (utility?.tpUpdated) showSuccess('Topic Updated Successfully');
      if (utility?.isGlobalStyle){
        showSuccess('Global Style Updated Successfully');
        dispatch(utilityAction.globalStyleUpdatedToast({}));
      } 
      if (utility?.isCloned) {
        showSuccess('Learning Aid Cloned Successfully');
        dispatch(utilityAction.learningAidClonedToast({}));
      }
    };
  
    // handle toast messages for the topic
    if (isLastTopic) handleTopicMessages();
  
    // handle utility-related messages
    handleUtilityMessages();
  
  }, [isLastTopic, utility]);


  /** here topic is type ...bad naming  */
  const handleDrawer = ( topic, width ) => {
    const mediaEle = document.querySelectorAll( 'video, audio' )
    if ( mediaEle.length !== 0 )
      for ( const media of mediaEle ) {
        media.pause()
      }
    dispatch( courseInfoAction.setDrawer( { value: true, topic, width } ) );
    dispatch(courseTrackingAction.saveCourseProgress(trackInfo))
  }



const handleAttestationCompletion=(e,key)=>{
    if((!attestationCheck && globalStyle?.attest?.c?.trim()) || hasClicked){
      e.stopPropagation()
      return;
    }
    handleAttestation(key)
    e.stopPropagation()
}
/**
 *  Handling session set actions and attestation
 * Add your session-related actions and attestation handling logic here
 */ 
const handleAttestation=(key)=>{
  crsStorage=storage[`${configId}`]?.get()
  let obj=crsStorage
  /**
   * Check if the certification feature is disabled and if the key is -1
   * Set the attestation flag to "3" indicating the attestation state
  */ 
  if(!globalStyle?.headers?.c && String(key)==='-1'){
    handleAttestation("3");
    // once score is 100, we are not showing the attestation popup
    if(BuildHelper.isOnScorm()){
      trackInfo.scorm.setScoreRaw(100);
      trackInfo.scorm.commit();
    }
    dispatch(courseTrackingAction.saveCourseProgress(trackInfo));
    return;
  }
  if(crsStorage?.attestation !== String(key) && crsStorage?.attestation !== "3"){
    obj={...obj,attestation:String(key)||-1}
    storage[`${configId}`]?.set(obj);
  }
  
}

const notifyCompletion= async () => {
  try {
    // check conditions for notifying email 
     crsStorage=storage[`${configId}`]?.get()
    if (Number(trackInfo?.courseTracking?.completed_mail) !== 1  && crsStorage.mail !=='1' && !isEnd) {
      // dispatch the email
      if(!BuildHelper.isOnScorm()) await dispatch(courseTrackingAction.courseCompletionNotifying());
      let obj=crsStorage
      obj={...obj,mail:'1', attestation:getAttestation}
      storage[`${configId}`].set(obj);
    }
  } catch (e) {console.error(e);}
};

//print action
const handlePrinting=()=>{
  handleDrawer('Certificate', '80%');
  handleAttestation("3");
}
/**
 * During the attestation popup, we listen for clicks on <a> tags.
 * If any <a> tag is present, the checkbox is disabled.
 * The checkbox is enabled only after the <a> tag is clicked and viewed.
 */
const [hasClicked, setHasClicked] = useState(false)
useEffect(() => {
  if(!globalStyle?.attest?.t) return;
  const container = document.querySelector(".attest-click-event");
  if (container) {
    const links = container.querySelectorAll("a");
    setHasClicked(links.length > 0); // initially set if there are any <a> tags
  } else {
    setHasClicked(false); // no <a> tags if container is missing
  }

}, [globalStyle?.attest?.t]);

const handleLinkClick = (event) => {
  if(!globalStyle?.attest?.t) return;
  const container = document.querySelector(".attest-click-event");
  let clickedLinks = [];
  const link = event.target.closest("a");
  if (link) {
    const href = link.getAttribute("href");
    clickedLinks.push(link); // Mark this link as clicked
    if (clickedLinks.length === container.querySelectorAll("a").length) {
      // All links have been clicked
      setHasClicked(false);
    }
  }
};


/**
 * attestation component that displays a modal for user attestation.
 * depending on the user's attestation status, it shows different buttons
 * and content. If the attestation status indicates a printing action,
 * it adjusts the modal's title and dimensions accordingly.
 * 
 * -1 not active
 * 1 active Attestation
 * 2 active Print certificate completed view 
 * 3 completed print certificate page
 * 4 declined
 * 
 * uses memoization to optimize performance by avoiding unnecessary re-renders.
 * 
 */
const Attestation = React.useMemo(
  () => {

    const isPrintAction=crsStorage?.attestation==="2";
    /**
     * defines button configuration based on the presence of the certification feature.
     * checks if the certification feature is enabled, and if `isPrintAction` is true, 
     * it will display the print certificate button. Otherwise, it handles the attestation process.
     */
    const isAttestNotEmpty = globalStyle?.attest?.c?.trim();
    const isDeclined = !globalStyle?.headers?.c && isPrintAction;
    const isAccepted = attestationCheck || isPrintAction || (!hasClicked && !isAttestNotEmpty);
    const buttonConfig = (isDeclined) ? [] : [
      {
          text: t("DECLINE"), 
          func: (e) => handleAttestation("4"),
      },
      {
          text: isPrintAction ? t("P_CERTIFICATE") :(isAttestNotEmpty ?  t("ACCEPTTERMS") : t("CONTINUE")), 
          func:(e) => isPrintAction ? handlePrinting(e) : handleAttestationCompletion(e, "2"), 
          color: (isAccepted) ? "#fff" : "#000",
          bgColor: (isAccepted) ? "#13984b" : "#a5a5a5",
      },
    ];
    const hasOpen=!['-1','4','3'].includes(crsStorage?.attestation) && (memoizedIsCourseCompleted || isEnd)
   return ( <CommonModal
    isOpen={hasOpen}
    title={isPrintAction ? "": t("ATTESTATION")}
    bgColor={"#ffff"} 
    className={"attestation-model end-attest"}
    width={isPrintAction ? "30%" : "40%"}
    height={(isPrintAction) ? "30%" : 'auto'}
    buttons={buttonConfig}
    outsideClick={false}
    content={ attestationContent(crsStorage, handleLinkClick, globalStyle, isAttestNotEmpty, attestationCheck, hasClicked, setAttestationCheck)}
    onClose={()=>handleAttestation("-1")}
    shadow="none"
  />
)
    },
  [ crsStorage, attestationCheck,hasClicked]
);


  return (
    <>
     {/* <DndProvider backend={HTML5Backend}> */}
      { !DataHelper.topicplayer() && <NavBar navigateHome={uiNavigationHandler} handleAttestation={(key)=>handleAttestation(key)} handleStyle={applyViewInDevice} userInfo={userInfo} courseCompleted={!memoizedIsCourseCompleted}/>}
      {(ceAccetsInfo?.isLoading || courseEdit?.isLoading || courseInfo?.isLoading || globalStyle?.isLoading) &&  <Loader />}
      <div className={`wrapper container`}>
      {((ctour === null) && BuildHelper.isLearner() && !window.ce.isStandalone) && <QuickTour/>}
        <div>
          {/* common loader used for the course route */}
          {!courseInfo.loading && <>
           { !DataHelper.topicplayer() &&
          <div className={`expand-tree-icon ${courseInfo.sideBar && 'show-expand-icon'} ${expandTree && 'expand-tree-big'}`} onClick={toggleExpand}>
            <img src={require('../../assets/img/expand_tree.png').default} />
          </div>}
          {/* attestation popup */}
           {globalStyle?.headers?.cp && Attestation}
          <div ref={sideBarRef} className={`${courseInfo.sideBar ? `${DataHelper.topicplayer()? 'display-none': 'sidebar open'}` : "sidebar collapsed-sideBar"} ${expandTree && 'expand-sideBar'}`} >

          <TreeMenu navigationHandler={navigationEventHandler} elmentItem={scrlRef} courseList={courseList} isOpen={`${courseInfo.sideBar ? '' : 'hideText'}`}  />
            </div>
            </>
          }
                    <div ref={scrlRef} onScroll={handleScroll} id="layout-scroll" className={`${courseInfo.sideBar ? `${DataHelper.topicplayer()? 'content close':'content open'}` : `content` }  ${isGlobalEdit}`}  >
            {/* {console.log('courseinfo', courseInfo)} */}
            {Object.keys(courseInfo.topic).length > 0 &&
            <div className={`comp-layout ${viewInDevice?.class} ${Number(window?.ce?.ceuser?.role_id || 4) === 4 && "lrn-mode"}`} style={viewInDevice}>
                {
                  Number(userInfo.user.role_id) !== 4 ? 
                  <HoverHighlighter disabled={viewState.isHighLight}>
                    <ScrollLayout />
                  </HoverHighlighter>
                  :
                  <ScrollLayout />
                }
                <EditDrawer />
                {!DataHelper.topicplayer() &&
                  <div className='next-section skip-section'>
                    {courseInfo.nav.prev?.title ? <AppButton
                    theme={"primary2"}
                    size="medium"
                      disabled={isFirstTopic(courseInfo?.topic)}
                      // className={`${isFirstTopic(courseInfo?.topic) ? "disabled-btn" : "btn-prev"} nav_btn`}
                      onClick={() => goToNextTopic(courseInfo.nav.prev, true)}>{t("BACK")}</AppButton> : <AppButton theme={"primary2"}  size="medium" onClick={() => { goHome() }} >{t("HOME")}</AppButton>}
                    {courseInfo.nav.next?.title ? <AppButton theme={"primary2"}  size="medium" onClick={() => goToNextTopic(courseInfo.nav.next, false)} >{t("NEXT")}</AppButton> : <AppButton  size="medium" style={nextButtonStyle}
                      disabled={!isEnd}
                      theme={"primary1"}
                      onClick={() => setCompLmsData()}
                    >{t("END")}<span className='next-txt'></span></AppButton>}
                  </div>}
              </div>
            }
          </div>
          {!courseEdit?.isOpen &&
          <ScrollButton 
           position="bottom-right" 
         />}
        </div>
      </div>
      {/* </DndProvider> */}
    </>
  );
}

Layout.defaultProps = {
}

export default Layout;

/**
 * hotkeysConfig: Defines a list of key combinations and their respective actions.
 * Each keyCombo is mapped to a specific action that is dispatched to handle shortcuts.
*/
function hotKeyFunObj(dispatch, trackInfo) {
  return [
    {
      keyCombo: 'ctrl+alt+1',
      action: () => {
        // console.log('Manual Completion');
        dispatch(courseTrackingAction.shortcutCompletion(trackInfo.flatten));
      }
    },
    {
      keyCombo: 'ctrl+alt+8',
      action: () => {
        dispatch(courseEditAction.toggleDrawer({
          open: true,
          target: {},
          itemInfo: { name: "Topic Validation Dashboard" },
          panel: 14,
          drawerWidth: 25
        }));
      }
    }
  ];
}

function attestationContent(crsStorage, handleLinkClick, globalStyle, isAttestNotEmpty, attestationCheck, hasClicked, setAttestationCheck) {
  return <>
    {crsStorage?.attestation === "2" ?
      <div className='success-message crc-completion'>
        <CheckCircleOutlineIcon className='success' />
        <h2>{t("CONGRATULATION")}</h2>
        <p className='sub-success'>{t("COMPLETE_MSG")}</p>
      </div> :
      <div onClick={handleLinkClick}>
        {globalStyle?.attest?.t ? <div className='attest-click-event' dangerouslySetInnerHTML={{ __html: globalStyle?.attest?.t || "" }} /> :
          <p>{t("ATTESTATION_MSG")}</p>}
        {isAttestNotEmpty && <CustomCheckbox
          className='attest-ck-check-box'
          label={globalStyle?.attest?.c || t("ATTESTATION_LABEL")}
          checked={attestationCheck}
          disable={hasClicked}
          name="n"
          onChange={(e) => setAttestationCheck(e?.target?.checked)} />}
      </div>}
  </>;
}
