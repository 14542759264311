import React, { useState,useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import './documentviewer.scss'
import ContentTracking from '../../../core/progress/ContentTracking'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Tooltip from '@material-ui/core/Tooltip';
import PlayerConstants from '../../../utils/PlayerConstants';
import Instruction from '../../ui/Instruction/Instruction';
import DataHelper from '../../../utils/DataHelper';
import DownloadIcon from '@mui/icons-material/Download';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LALoader from '../../ui/Loader/LALoader';
import t from '../../../translation/useTranslate';

/**
 * 
 * @param {*} selector 
 * returns content area selector for tracking
 * @param {*} callback
 * returns for tracking the content area when scrolling
 * @param {*} isLongContent 
 * returns boolean value if the content area is large
 */

const debouncing = (selector, callback, isLongContent) => {
    const isView = isLongContent || null
    setTimeout(() => {
        let element = document.querySelector(selector);
        let position = element?.getBoundingClientRect();
        const isMobile = (window.matchMedia('max-width: 768px').matches || window.matchMedia('max-height: 450px').matches)
            // checking for partial visibility
        if((position?.top < window.innerHeight && position?.bottom >= 0) && (isMobile || isView)) {
            callback()
        } else {
            // checking whether fully visible
            if(position?.top >= 0 && position?.bottom <= window.innerHeight) {
                callback()
            }
        }
    
    }, 100)
}


const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS
const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS

/**
 * 
 * @param {*} header 
 * @returns header title of the component
 */

/**
 * 
 * @param {*} description 
 * @returns content description of the component
 */

/**
 * 
 * @param {*} cename 
 * @returns component name
 */

/**
 * 
 * @param {*} title 
 * @returns top heading title of the component
 */


const DocumentViewer = ({ description, header, footer, layout, textPosition,  filePath, docPublicUrl, enablePublicUrl, thumbnail, fileName, track, handler, cename, styleObj, tp_status}) => {

    const contentLayout = document.querySelector('.content')
    const [status, setStatus] = useState({completed: false, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE})

    const iframeRef = useRef(null)
    const [iframeKey, setIframeKey] = useState(0);
    const [loadAttempts, setLoadAttempts] = useState(0); // Tracks reload attempts
    const [iframeLoaded, setIframeLoaded] = useState(false);
    const MAX_ATTEMPTS = 5;
    /**
     * Manage the component update 
     * progress logic in this method
     * 
     * Update the view status when ever the user interacts
     * Update the progess status ONLY ONCE, when status completes
     * 
     */
      // Function to check and reload iframe if it hasn't loaded
  const reloadIframe = () => {
    if (iframeRef.current) {
      setIframeLoaded(false); // Reset iframe loaded status before reloading
      setIframeKey((prevKey) => prevKey + 1); // Trigger re-render by updating key
    }
  };
  useEffect(() => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;

      // Set up load event listener
      const onLoad = () => {
        setIframeLoaded(true); // Set iframe as loaded
      };

      iframe.addEventListener('load', onLoad);

      // Reload the iframe if it has not been loaded after 3 seconds
      const timer = setTimeout(() => {
        if (!iframeLoaded) {
          reloadIframe();
        }
      }, 3000); // Retry after 3 seconds

      return () => {
        clearTimeout(timer); // Clean up timer on component unmount or iframe change
        iframe.removeEventListener('load', onLoad); // Clean up the load listener
      };
    }
  }, [iframeKey, iframeLoaded]);
     const updateProgress = () => {

        /**
         * View status, will update while navigating to next topic
         */
        track.state = { ...track.state, c:1}
        /**
         * Progress updated as and when its completed
         */
    
        if (!track.status) {
          track.status = 1;
          if (track.status) {
            setStatus({completed: true, text:PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE})
            handler({ type: UPDATE_STATUS, id: track.id, name:cename, isolated: true })
          }
        }
        /**
         * If anything to be intimated always
         * call this
         */
        // handler({ type: UPDATE_STATUS, id: track.id, name:cename })
    
    }
    
    
    const handleScroll = ()=> {
      DataHelper.scrollCompletionDebouncing('section[name=DocumentViewer]', updateProgress, true)
  }

  useEffect(()=>{
    if(layout===3){
      contentLayout?.addEventListener('scroll', handleScroll)
    }
    else{
      contentLayout?.removeEventListener('scroll', handleScroll)
    }
  }, [layout])

    const updateProps = ()  =>{
        //console.log(this)
    }

    useEffect(()=>{
        if(tp_status > -1) updateProgress();
    },[tp_status])


    const getImageUrl = (path)=>{
      return DataHelper.getResourcePath(0,path);
  }
  const s3PathDocs = PlayerConstants.S3DOCSPATH

  const getDocsUrl = (path, embedScorm, publicUrl = false)=>{
    
    // If public URL is requested, use it if available; otherwise, use the S3 path
    // if(publicUrl){
    //   return ( docPublicUrl && docPublicUrl?.length > 0) ? docPublicUrl :`${s3PathDocs}${path}`
    // }
     
    // If embedding in SCORM is true and publicUrl is false , always use the S3 path
    if(embedScorm){
      return `${s3PathDocs}${path}`
    }

    // Default case: use the path generated by DataHelper.getResourcePath function
    return DataHelper.getResourcePath(6,path);
  }

  
  /**
   * 
   * If SCORM packaging is enabled, generate the relative(content folder) URL for SCORM from where it will download document
   * If SCORM packaging is not enabled, generate a URL to view the document online
   * 
   */
  const hrefDocs =  window?.ce?.isScormPack ? getDocsUrl(fileName, false, false) : fileName.endsWith('.pdf') ? `https://docs.google.com/viewer?url=${getDocsUrl(fileName, false, enablePublicUrl)}&embedded=true`   :`https://view.officeapps.live.com/op/view.aspx?src=${getDocsUrl(fileName, false, enablePublicUrl)}`

  
  const handleDownload = (fileName) => (e) => {
    window.open(getDocsUrl(fileName, false, window?.ce?.isScormPack ? false : enablePublicUrl)) // false(second arg) for download from content folder "only for scorm"
  }
  

  const handleOpenInNewWindow = (fileName) => (e) => {
    const isScorm = window?.ce?.isScormPack || false

    // Generate the URL for viewing the document, considering SCORM status and public URL option
    const embedUrl = getDocsUrl(fileName, isScorm, enablePublicUrl)

    window.open(fileName.endsWith('.pdf') ? `https://docs.google.com/viewer?url=${embedUrl}&embedded=true` : `https://view.officeapps.live.com/op/view.aspx?src=${embedUrl}`, "_blank")

  }
  
  


    return (
      <>
        <Instruction
          isInstruction={false}
          completed={track.status === 1 || status.completed}
          title={
            track.status === 1 || status.completed
              ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE
              : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE
          }
          classText={`${
            (track.status === 1 || status.completed) &&
            PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS
          }`}
        />
        <div className="topic-div">
          <div className="document-container">
            <p className='doc-title fw-bold fs-3'>{header}</p>
            <div className={`doc-layout-container layout-${layout} txt-pos-${textPosition}`}>
            { layout === 3 ? <div className='iframe-container'>
            <div className='iframe-download-docs'>
                <button className='' title={t("DOWENLOAD")} onClick={handleDownload(fileName)}><DownloadIcon/></button>
                <button className='' title={t("OPEN_IN_WINDOW")}onClick={handleOpenInNewWindow(fileName)}><OpenInNewIcon/></button>
            </div>
            {/* <iframe
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${ getDocsUrl(fileName, window?.ce?.isScormPack||false, enablePublicUrl) }`}
              ref={iframeRef}
              allow-same-origin="true"
            ></iframe> */}
            {!iframeLoaded && <LALoader />}
            <iframe
              src={fileName.endsWith('.pdf') ? `https://docs.google.com/viewer?url=${getDocsUrl(fileName, window?.ce?.isScormPack || false, enablePublicUrl)}&embedded=true` : `https://view.officeapps.live.com/op/embed.aspx?src=${getDocsUrl(fileName, window?.ce?.isScormPack || false, enablePublicUrl)}`}
              ref={iframeRef}
              key={iframeKey} // Set key to re-render the iframe
              allow="same-origin"
            ></iframe>

            </div> : <div className='thumb-image'>{thumbnail && thumbnail?.length !== 0 && <a href={hrefDocs} onClick={(e) => { updateProgress(); }} target='_blank' rel="noreferrer"><img alt='doc thumbnail' src={getImageUrl(thumbnail)}/></a>}</div>}
            <p className='doc-description fs-5' dangerouslySetInnerHTML={{__html: description}}></p>
            </div>
          </div>
        </div>
      </>
    );
}
 
DocumentViewer.propTypes = {
    /** Heading title text of the component */
    header: PropTypes.string,
    /** Description text is used for displaying explanation over the topic  */
    description: PropTypes.string,
    /** Tracking the component progress */
    track:PropTypes.instanceOf(ContentTracking),
    /** Func description for tracking*/
    handler:PropTypes.func
}

export default DocumentViewer