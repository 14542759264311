const BuildHelper = {
    config :{},
    initialize : (cnf) =>  {
        BuildHelper.config = {}
        BuildHelper.config.env = process?.env;
        BuildHelper.config.isscorm = cnf.scorm || false;
        BuildHelper.config.roleId = cnf.roleId || 4;
    },
    isOnScorm : () =>   {
        return BuildHelper.config.isscorm;
    },
    configId :() => {
        return  window.ce.rlm.config_id
    },
    crsId :() => {
        return  window?.ce?.platform_scorm?.cid
    },
    roleId: () => {
        return BuildHelper.config.roleId;
    },
    isLearner :()   =>{
        return Number(BuildHelper.config.roleId||window?.ce?.ceuser?.role_id) === 4
    },
    isLocalRun :() => {
        return  ( window.location.href.includes("localhost") ? true : false)
    },

    isConfig :() => {
        return  ( window?.ce?.ceuser?.type ==="config" ? true : false)
    },
    languageList:()=>{
        return (window?.ce?.platform_scorm?.lnlist)
    },
    hasPreAttestation:()=>{
        let value = localStorage.getItem(`${BuildHelper.configId()}_AT`);
        value = value ? JSON.parse(value) : null;
        return value
    },
    hasV30:()=>{
     return window?.location?.pathname.includes("v_30") 
    },
    hasLocked:()=>{
        return window?.ce?.platform_scorm?.isUnlocked
    },
     defaultLanguage : () => {
        try {

            if(!window?.ce)return;
            // Get the list of available languages from the list
            const languages = BuildHelper?.languageList()?.languages;
 
            // here is only one language available and no value in local storage, set it as the default
            if(languages?.length === 1 || !languages?.length || !languages){
                const defaultLn = languages ?  languages?.length > 0 ? languages[0]?.lan :  "en_US"  :  "en_US";
                localStorage.setItem(`${BuildHelper.configId()}_LN`, JSON.stringify(defaultLn));
                return defaultLn;
            }
            
            // Retrieve the default language from local storage
            let value = localStorage.getItem(`${BuildHelper.configId()}_LN`);
            value = value ? JSON.parse(value) : null;
    
            // Return the value from local storage if available
            if(value==="null") value=null
            return value;
        } catch (e) {
            console.error('Error fetching or setting default language:', e);
            return false;
        }
    },
    isStandalone:()=>{
        return (window.ce.isStandalone)
    },
    getExternalPath: () =>{
        return  window.sessionStorage.getItem('external-path') || ''
    }

}


export default BuildHelper;
