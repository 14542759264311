import React from 'react'
import './dashboard.scss'
import UpdateIcon from '@material-ui/icons/Update';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import Badge from '../../components/ui/Badge/Badge';
import ProfileHeader from '../../components/ui/ProfileHeader/ProfileHeader';

const DashBoard = props => {
    return (
        <div className='dashboard-container'>
            <div className='dashboard-main'>
                <h3>DashBoard</h3>
                <ProfileHeader name={window.ce?.ceuser?.firstname}/>
                <div className='profile-badges'>
                    <div className='badge-row row'>
                        <div className='col-md-4'>
                           {/* <Badge icon={<FontAwesomeIcon icon={faGraduationCap} />} textColor={'#F19D39'}  text={'COURSES TO DO'} courses={'31'} color={'#FEF2E4'}/> */}
                        </div>
                        <div className='col-md-4'>
                            <Badge icon={<UpdateIcon />} textColor={'#047FB8'} text={'OVERDUE COURSES'} courses={'02'} color={'#DCF1FC'}/>
                        </div>
                        <div className='col-md-4'>
                            <Badge icon={<VerifiedUserIcon/>} textColor={'#13984B'} text={'COMPLETED COURSES'} courses={'01'} color={'#C5FFDD'}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

DashBoard.propTypes = {

}

export default DashBoard
