import { Button } from '@material-ui/core'

import React, { useEffect, useState, } from 'react'
import PropTypes from 'prop-types'
import './authorinfo.scss'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import TextField from '@material-ui/core/TextField';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
import ACLHelper from '../../../utils/ACLHelper'
import DataHelper from '../../../utils/DataHelper';
import MuiIcons from '../../../components/ui/MuiIcons/MuiIcons';
import PlayerConstants from '../../../utils/PlayerConstants';
import useDate from '../../../utils/hooks/useDate';

/*
 * this component mainly based on giving info about the creator/modifier of content
 * We have logs [-1, -2,-3] in the LRN, which indicate customized
 * -1: Customized LRN (applied during cloning)
 * -2: New LRN (created after cloning)
 * -3: LRN after applying the template
 * 1: newly added one(using + add)
 * [-1,-2,-3,7,1]- resolved (7,1 we have edit option)
 * 5- active
 * 6- ToDo
*/

const AuthorInfo = ( { itemInfo, handler } ) => {

    const cancelComments = () => {
        handler( { type: 'close' } )
    }

    const [ commentsList, setCommentsList ] = useState( ( itemInfo?.logs || [] ) );
    const [ userComment, setUserComment ] = useState( {} );
    const userInfo = useSelector( state => state.userInfo );
    const [ editMode, setEdit ] = useState( null );
    // const [anchorEl, setAnchorEl] = useState(null);
    const [ contextMenu, setContextMenu ] = useState( { anchor: null, index: 0, open: false } )
    let hasConfigLRN=DataHelper?.hasCustomized(itemInfo);
    const [ cmtStatus, setCmtStatus ] = useState( 1 );
    const { formatDate} = useDate();

    useEffect( () => {
        console.debug( "userInfo", userInfo.user );
        console.debug( "lastname", userInfo?.user?.lastname )
        console.debug( "firstname", userInfo?.user?.firstname )

    }, [ userInfo ] )

    const getLoggedInUser = () => {
        return userInfo?.user?.firstname + ' ' + userInfo?.user?.lastname;
        // return itemInfo?.author?.fname + ' ' + itemInfo?.author?.lname
    }


    useEffect( () => {
        if ( itemInfo ) {

            // itemInfo.logs.sort((a, b) => {
            //     return new Date(a.modified) -
            //         new Date(b.modified)
            // }).reverse();
            let logs=[...(itemInfo?.logs||[])]
            // if(!DataHelper.isCourse()){
            //     logs= logs?.filter(log=>(log?.status !== -1 && log?.status !== -2))
            // }

            setCommentsList( logs )

            setUserComment( {
                id: itemInfo?._id, comment: {
                    userid: 'Get and update later', status: 5, type: 1, modified: Date.now(),
                    name: getLoggedInUser(), comments: ''
                }
            } )
        }
    }, [ itemInfo?.logs ] );

    const onCommentChange = ( event ) => {
        let cmtObj = { ...userComment, comment: { ...userComment.comment, comments: event.target.value } };
        setUserComment( cmtObj )
        // console.log(cmtObj)
    }


    const saveComments = () => {
        try {
            if ( userComment?.comment?.comments !== '' ) {
                /** ( Moved EditDrawer to here)
                 * Get this user id from celrs mongo 
                 * once the user logged in
                 * update the root property (window.ce)
                 * uncomment the below line.
                 * As of now getting statically
                 */
                // hinfo.comment_data.userid = userInfo.user.uid;
                //  let userid = '618ca734337d4023cdf85581';
                /**
                 * After saving get the data updated in this
                 * courseInfo.topic.lrncontent
                 * find the current item with the ID and 
                 * update the log list
                 */ 

                let updatedValue={...userComment}
                 updatedValue.comment.userid=window?.ce?.ceuser?.uid;
                
                 if(hasConfigLRN){//this check and this payload are only for configuring non-customized learning elements
                    let {type,comments, ...comment}=userComment?.comment;
                    updatedValue={
                        lrnid: userComment?.id,
                        configid: window?.ce?.rlm?.config_id,
                        userid: updatedValue?.comment?.userid,
                        name:`${userInfo?.user?.firstname} ${userInfo?.user?.lastname}`,
                        type:1,
                        status:itemInfo?.status,
                        comments:{...comment,ctype:type, content:comments}
                    }
                 }
                handler( { type: 'save_comment', data: updatedValue } );
            }
        } catch ( e ) {
            console.debug( e );
        }
    }

    /** Role ID 
    * 1- Editor
    * 4- Player
    * only for the editor should be enabled
     */
    const isEditorEnabled = () => {
        return Number( userInfo?.user?.role_id ) !== 4
    }



    const handleUpdate = ( e, index ) => {
        let payload = {
            id: itemInfo?._id,
            index: index,
            comments: commentsList[ index ]?.comments,
           path:'p1'
        }
        if(hasConfigLRN){//this check and this payload are only for configuring non-customized learning elements
            payload={
                lrnid: userComment?.id,
                configid: window?.ce?.rlm?.config_id,
                userid: window?.ce?.ceuser?.uid,
                commentid:commentsList[ index ]._id,
                comment:commentsList[ index ]?.comments,
                flag:1,
                path:'p2'
            }
        }
        handler( { type: 'update_comment', data: payload } )
        setEdit( null )
        setCommentsList( commentsList )
        e.stopPropagation();
        handleClose();
    }

    const handleClose = () => {
        // setAnchorEl(null);
        const cntx = { ...contextMenu, anchor: null, open: false }
        setContextMenu( cntx )
    };


    const handleChangeInput = ( e, index ) => {
        commentsList[ index ][ e.target.name ] = e.target.value
    }

    /**
     * 
     * @param {*} item 
     * @returns 
     * - 1 cloned
     * - 3 Template applied 
     */
    const isOptionsEnabled = ( item ) => {
        const blockedStatus = [ -1, -2, -3, -4 ];
        return blockedStatus.indexOf( item?.status ) === -1 ? true : false
    }



    const handleClick = ( event, index ) => {
        setContextMenu( { anchor: event.currentTarget, index: index, open: Boolean( event.currentTarget ) } )
        // setAnchorEl(event.currentTarget);
    };

    const deleteHandler = (e, index) => {
        // setAnchorEl(null);
        let payload = {
            id: itemInfo?._id,
            index: contextMenu.index
        }
        if(hasConfigLRN){//this check and this payload are only for configuring non-customized learning elements
            payload={
                lrnid: userComment?.id,
                configid: window?.ce?.rlm?.config_id,
                userid: window?.ce?.ceuser?.uid,
                commentid:commentsList[ index ]._id,
                flag:2
            }
        }
        handler( { type: 'delete_comment', data: payload } )
        const removed = commentsList.filter( ( e, i ) => i !== contextMenu.index )
        setCommentsList( removed )
        const cntx = { ...contextMenu, anchor: null, open: false }
    }
    const editHandler = () => {
        // setAnchorEl(null);
        setEdit( contextMenu.index )
        const cntx = { ...contextMenu, anchor: null, open: false }
    }

    // const handleCommentEdit = (e, index) => {
    //     setEdit(index)
    //     e.stopPropagation()
    // }

    // const handleDelete = (e, index) => {
    //     let payload = {
    //         id: itemInfo?._id,
    //         index: index
    //     }
    //     handler({ type: 'delete_comment', data: payload })
    //     const removed = commentsList.filter((e, i) => i !== index)
    //     setCommentsList(removed)
    //     e.stopPropagation()
    // }


    const handleStatusClick = (cmt,index) => {
       let statusIds=["5",'6','7'];
       // Find the current index of the previous status ID
       const currentIndex = statusIds.indexOf(String(cmt?.status));
        // Calculate the next index and wrap around using modulus
        const nextIndex = (currentIndex + 1) % statusIds.length;
        //setCmtStatus( statusIds[nextIndex]);
       const payload={
        lrnid: userComment?.id,
        id: cmt?._id,
        status:statusIds[nextIndex],
        path:'p3'
       }
       handler( { type: 'update_comment', data: payload });
       commentsList[ index ]={...cmt,status:Number(statusIds[nextIndex])}
      };

    return (
        <div className='author-info-container'>
            <div className='author-info-main'>
                <p className='author-id'>ID No: <span >{ itemInfo?._id }</span></p>
                <div className='author-list  row'>

                    <div className='col-md-6'>
                        <p className='topic-title'>Author</p>
                        <p className='author-name'>{ itemInfo?.author?.fname }</p>
                    </div>
                    <div className='col-md-6'>
                        <p className='topic-title'>Created on</p>
                        <p className='created-date'> { formatDate(itemInfo?.createdAt, 'MM/DD/YYYY h:mm' ) }</p>
                    </div>
                    { commentsList.length > 0 ?

                        < >
                            <div className='col-md-6'>
                                <p className='topic-title'>Modified by</p>
                                <p className='author-name'>{ commentsList[ 0 ].name }</p>
                            </div>
                            <div className='col-md-6'>
                                <p className='topic-title'>Last updated on</p>
                                <p className='created-date'> {formatDate(commentsList[ 0 ].modified, 'MM/DD/YYYY h:mm' ) }</p>
                            </div>
                        </ >
                        : <></> }
                </div>
                {ACLHelper.isFeatureEnabled(2,1) &&  <div className='author-info-text-area'>
                    <div className='text-input'>
                        <textarea value={ userComment?.comment?.comments } className='comment-text-area' onChange={ onCommentChange } ></textarea>
                    </div>
                    <div className='action-info'>
                        <Button className='cancel-btn' onClick={ () => { cancelComments() } }>Cancel</Button>
                        <Button className='submit-btn' onClick={ () => { saveComments() } } >Submit</Button>
                    </div>
                </div>}
                { commentsList.length > 0 && commentsList.map( ( item, index ) => {
                    return <div className='author-comment-section' key={ item._id.toString() }>
                        <div className='comment-row row'>
                            <div className='profile-badge col-md-2'>
                                <span className='profile-latter'>{DataHelper.getInitials(item?.name)}</span>
                                
                            </div>
                            <div className='col-md-6'>
                                <p className='profile-name '>{ item.name }</p>

                                <p className='modified-date'>{ formatDate(item.modified, 'MM/DD/YYYY h:mm' ) } </p>
                            </div>
                            <div className='profile-type col-md-3'>
                                <p>Author</p>
                            </div>
                            <div className='profile-settings col-md-1'>
                                { isEditorEnabled() && isOptionsEnabled( item ) && <span className='profile-settings-icon'
                                    data-index={ index }
                                    aria-controls={ contextMenu.open ? 'basic-menu' : undefined }
                                    aria-haspopup="true"
                                    aria-expanded={ contextMenu.open ? 'true' : undefined }
                                    onClick={ ( e ) => handleClick( e, index ) }
                                >
                                   {(ACLHelper.isFeatureEnabled( 2, 2 ) || ACLHelper.isFeatureEnabled( 2, 3 )) &&<MoreHorizIcon />}
                                    </span> }
                            </div>
                        </div>
                        <div className='comment-text'>
                            { !isEditorEnabled() && <p><span className='tag-text'> </span>{ item.comments }</p> }
                            { isEditorEnabled() && !isOptionsEnabled( item ) &&<div className='comment-edit'> 
                                  <p><span className='tag-text'> </span>{ item.comments } </p> 
                                  <MuiIcons iconName={`${PlayerConstants.COMMENTS_STATUS[item?.status]?.status.toLowerCase()||'resolved'}_comments`} data-tooltip={PlayerConstants.COMMENTS_STATUS[String(item?.status)]?.status} color={PlayerConstants.COMMENTS_STATUS[String(item?.status)]?.color || '#13984B' } className="p-0 pl-2 status-icon"  disabled/>
                                 </div>
                                 }
                            { isEditorEnabled() && isOptionsEnabled( item ) && <div className='comment-edit'>
                                { editMode === index ? <>
                                    <TextField id="outlined-basic" spellCheck={ false } name='comments' label="Editing..." variant="outlined"
                                        defaultValue={ item?.comments } onClick={ ( e ) => e.stopPropagation() }
                                        inputProps={ { maxLength: 60 } }
                                        multiline
                                        maxRows={ 4 }
                                        onChange={ ( e ) => handleChangeInput( e, index ) } />
                                    <CheckIcon className='save-icon' onClick={ ( e ) => handleUpdate( e, index ) } />
                                </> : <>
                                    <Typography className={ `` }>{ item.comments?.replace(
                                            'Copied from course for editing',
                                            'Started customizing') }   </Typography>
                                     <MuiIcons iconName={`${PlayerConstants.COMMENTS_STATUS[item?.status]?.status.toLowerCase()||'resolved'}_comments`}  onClick={()=>handleStatusClick(item, index)}  data-tooltip={PlayerConstants.COMMENTS_STATUS[item?.status]?.status} color={PlayerConstants.COMMENTS_STATUS[item?.status]?.color || '#13984B' } disabled={!ACLHelper.isFeatureEnabled( 2, 2 )} className="p-0 pl-2 status-icon" />
                                    { contextMenu.index === index && contextMenu.open && <div className='icons-holder'>
                                        {ACLHelper.isFeatureEnabled( 2, 2 ) && <EditIcon className='edit-icon' onClick={ editHandler } /> }
                                        { ACLHelper.isFeatureEnabled( 2, 3 ) && <DeleteIcon className='delete-icon' onClick={(e)=> deleteHandler(e, index ) } /> }
                                    </div> }
                                </> }
                            </div> }
                        </div>
                    </div>
                } ) }
                {/* <Menu
                    id="basic-menu" className='comment-text'
                    anchorEl={anchorEl}
                    open={contextMenu.open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={editHandler}><EditIcon /></MenuItem>
                    <MenuItem onClick={deleteHandler}><DeleteIcon /></MenuItem>
                </Menu> */}
            </div>




        </div>
    )
}

AuthorInfo.defaultProps = {

}

AuthorInfo.propTypes = {
    /** itemInfo will pass information about the user logs and comments*/
    itemInfo: PropTypes.object,
    /**passing this handler to track user comments  */
    handler: PropTypes.func
}

export default AuthorInfo
