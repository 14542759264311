import { useMemo } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

// extend Day.js with the Relative Time plugin
dayjs.extend(relativeTime);

const useDate = () => {
  // format a given date
  const formatDate = (date, format = 'MM/DD/YYYY h:mm A') => {
    return dayjs(date).format(format);
  };

  const fromNow= (date) => {
    return dayjs(date).fromNow();
  };

  return useMemo(() => ({
    formatDate,
    fromNow
  }), []);
};

export default useDate;
