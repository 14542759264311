import * as types from '../reducers/types'

const initialState = {
    tplAssigned: false,
    lAidAdded: false,
    lAidDeleted: false,
    isSavedTp: false,
    isCloned: false,
    tpUpdated: false,
    isGlobalStyle:false,
    authFault:false,
    // any new socket warning are there will go 1,2,3..
    socketWarning:0,// 0 is off (1 is publish happened so we are telling some warning message to the user)
    hasStructure:false
}


const utilityReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case types.APPLY_TEMPLATE_TOAST:
            let isAssigned = false;
            if ( action?.payload?.status === 'success' ) {
                isAssigned = true;
            }
            return {
                ...initialState,
                tplAssigned: isAssigned,
            }
        case types.ADD_LEARNINGAID_TOAST:
            let isAdded = false;
            if ( action?.payload?.status === 'success' ) {
                isAdded = true;
            }
            return {
                ...initialState,
                lAidAdded: isAdded,
            }
        case types.REMOVE_LEARNINGAID_TOAST:
            let isDeleted = false;
            if ( action?.payload?.status === 'success' ) {
                isDeleted = true;
            }
            return {
                ...initialState,
                lAidDeleted: isDeleted,
            }
        case types.SAVED_AS_TEMPLATE_TOAST:
            let isSavedTp = false
            if ( action?.payload?.status === 'success' ) {
                isSavedTp = true;
            }
            return {
                ...initialState,
                isSavedTp: isSavedTp,
            }
        case types.LEARNING_AID_CLONED_TOAST:
            let isCloned = false
            if ( action?.payload?.status === 'success' ) {
                isCloned = true
            }
            return {
                ...initialState,
                isCloned: isCloned,
            }
        case types.TOPIC_UPDATED_TOAST:
            let updated = false
            if ( action?.payload?.status === 'success' ) {
                updated = true;
            }
            return {
                ...initialState,
                tpUpdated: updated
            }
        case types.GLOBAL_STYLE_UPDATED_TOAST:
            let isGlobalStyle = false
            if ( action?.payload?.status === 'success' ) {
                isGlobalStyle = true;
            }
            return {
                ...initialState,
                isGlobalStyle: isGlobalStyle,
            }
        case types.AUTH_GOES_WRONG:
            let isAuth = false
            if (action?.payload?.status === 'fault' && !state?.authFault) {
                isAuth = true;
            }
            return {
                ...initialState,
                authFault: isAuth
            }
        case types.SOCKET_WARNING:
                return {
                    ...initialState,
                    socketWarning: action?.payload
        }
        case types.CRS_STRUCTURE_TOST:
            let isUpdate = false;
            if ( action?.payload?.status === 'success' ) {
                isUpdate = true;
            }
        return {
            ...initialState,
            hasStructure:isUpdate,
        }
        default:
            return state
    }
}

export default utilityReducer